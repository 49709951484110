import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import logoURL from "../IMG_0169.JPG";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import imageURL from "./Girl.svg";
import "./Sign.css";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

function Sign({ show, handleClose, openLoginModal, onSignUpSuccess }) {
  const navigate = useNavigate();
  const [usaStates, setUsaStates] = useState([]);
  const [canadaStates, setCanadaStates] = useState([]);

  const [formData, setFormData] = useState({
    name: "",
    Phone: "",
    email: "",
    password: "",
    addresline1: "",
    addressline2: "",
    ziporpostalcode: "",
    state: "",
    country: "",
    usertype: "",
  });
  const [selectedType, setSelectedType] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [stateData, setStateData] = useState([]);
  const [isTermsChecked, setIsTermsChecked] = useState(false);

  useEffect(() => {
    fetch("https://vanityapi.adef.tech/Product/StateList")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setStateData(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleTermsCheckboxChange = () => {
    setIsTermsChecked(!isTermsChecked);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === "password") {
      const passwordRegex =
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
      const isValidPassword = passwordRegex.test(value);

      if (!isValidPassword) {
        const suggestions = [];

        // Check for length
        if (value.length < 8) {
          suggestions.push("Password should be at least 8 characters long.");
        }

        // Check for alphanumeric characters
        if (!/[A-Za-z]/.test(value)) {
          suggestions.push("Include at least one letter (a-z or A-Z).");
        }

        if (!/\d/.test(value)) {
          suggestions.push("Include at least one digit (0-9).");
        }

        // Check for special characters
        if (!/[@$!%*#?&]/.test(value)) {
          suggestions.push(
            "Include at least one special character (@, $, !, %, *, #, ?, or &)."
          );
        }

        setPasswordError(suggestions.join(" "));
      } else {
        setPasswordError("");
      }
    }
  };

  const handleTypeChange = (e) => {
    const value = e.target.value;
    setSelectedType(value);
    setFormData((prevData) => ({
      ...prevData,
      usertype: value,
    }));
  };

  const handleStateChange = (e) => {
    const value = e.target.value;
    setSelectedState(value);
    setFormData((prevData) => ({
      ...prevData,
      state: value,
    }));
  };

  const handleCountryChange = async (e) => {
    const value = e.target.value;
    setSelectedCountry(value);
    setFormData((prevData) => ({
      ...prevData,
      country: value,
    }));

    // Fetch states based on the selected country
    try {
      let response;
      let data;

      if (value === "U.S.A") {
        
        response = await fetch("https://vanityapi.adef.tech/Product/StateList");
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        data = await response.json();
        setUsaStates(data);
      } else if (value === "Canada") {
        response = await fetch("https://vanityapi.adef.tech/Home/canadastates");
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        data = await response.json();
        
        setCanadaStates(data);
      }
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  const handleSignUp = async (e) => {
    e.preventDefault();

    // Check if the password meets the necessary conditions
    const passwordRegex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    const isValidPassword = passwordRegex.test(formData.password);

    if (!isValidPassword) {
      // Show an error message for invalid password
      setPasswordError(
        "Password must be at least 8 characters with alphanumeric and special characters."
      );
      return; // Prevent registration if the password is not valid
    }
    if (!isTermsChecked) {
      // Show an error message or handle as needed
      return;
    }
    // Clear the password error if it was previously set
    setPasswordError("");

    // Continue with the registration process
    
    const url = "https://vanityapi.adef.tech/Customer/Register";
    const data = new URLSearchParams(formData);

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      const responseData = await response.json();

      if (responseData.responseCode === 3) {
        toast.success(responseData.responsemessage, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          onClose: () => {
            window.location = "/twostepotp?email=" + formData.email;
          },
        });

        setFormData({
          firstname: "",
          lastname: "",
          middlename: "",
          Phone: "",
          email: "",
          password: "",
          addresline1: "",
          addresline2: "",
          ziporpostalcode: "",
          state: "",
          country: "",
          type: "",
          city: "",
        });

        onSignUpSuccess();
      } else {
        // Show error toast message for other responseCodes
        toast.error(responseData.responsemessage, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } catch (error) {
      // Show error toast message for fetch error
      toast.error("An error occurred during registration.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      console.error("Fetch error:", error);
    }
  };
  const sortedStateData = stateData.slice().sort((a, b) => {
    // Assuming Statename is a string, you may need to modify the comparison based on your data
    return a.Statename.localeCompare(b.Statename);
  });
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      className="beforeloginmodals"
    >
      <Modal.Header closeButton>
        <Modal.Title>Sign Up</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid className="custom-container">
          <Row className=" login">
            <Col md={12}>
              <div className="mb-5">
                <Row>
                  <Col md={7}>
                    <img
                      src={imageURL}
                      alt="loginimage"
                      className="login-image mt-5"
                    />
                  </Col>
                  <Col md={5} className="login-form">
                    <div className="form-wrapper  d-grid justify-content-center">
                      <img src={logoURL} alt="Logo" className="logo m-auto " />

                      <Form className="login-pages" onSubmit={handleSignUp}>
                        <p>Create a new account</p>
                        <Form.Group controlId="formBasicName">
                          <Form.Control
                            type="text"
                            placeholder="First Name"
                            name="firstname"
                            value={formData.firstname}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                        <Form.Group controlId="formmiddlename">
                          <Form.Control
                            type="text"
                            placeholder="Middle Name"
                            name="middlename"
                            value={formData.middlename}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                        <Form.Group controlId="formlastname">
                          <Form.Control
                            type="text"
                            placeholder="Last Name"
                            name="lastname"
                            value={formData.lastname}
                            onChange={handleInputChange}
                          />
                        </Form.Group>

                        <Form.Group controlId="formBasicPhone">
                          <PhoneInput
                            defaultCountry={"us"} // Default country
                            enableAreaCodes={true}
                            disableCountryCode={true} // Hide country code
                            value={formData.Phone}
                            onChange={(value) =>
                              setFormData((prevData) => ({
                                ...prevData,
                                Phone: value,
                              }))
                            }
                          />
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                          <Form.Control
                            type="email"
                            placeholder="Email Address"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                          />
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                          <Form.Control
                            type="password"
                            placeholder="Password"
                            name="password"
                            value={formData.password}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                        {passwordError && (
                          <Form.Text className="text-danger">
                            {passwordError}
                          </Form.Text>
                        )}
                        <Form.Group controlId="formBasicType">
                          <Form.Control
                            as="select"
                            value={selectedType}
                            onChange={handleTypeChange}
                          >
                            <option value="" disabled>
                              Select User Type
                            </option>
                            <option value="Business">Business</option>
                            <option value="Personal">Personal</option>
                            <option value="Vendor">Vendor</option>
                          </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="formBasicAddress1">
                          <Form.Control
                            type="text"
                            placeholder="Address Line 1"
                            name="addresline1"
                            value={formData.addresline1}
                            onChange={handleInputChange}
                          />
                        </Form.Group>

                        <Form.Group controlId="formBasicAddress2">
                          <Form.Control
                            type="text"
                            placeholder="Address Line 2"
                            name="addressline2"
                            value={formData.addressline2}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                        <Form.Group controlId="formBasicCity">
                          <Form.Control
                            type="text"
                            placeholder="City"
                            name="city"
                            value={formData.city}
                            onChange={handleInputChange}
                          />
                        </Form.Group>

                        <Form.Group controlId="formBasicZIP">
                          <Form.Control
                            type="text"
                            placeholder="ZIP or Postal Code"
                            name="ziporpostalcode"
                            value={formData.ziporpostalcode}
                            onChange={handleInputChange}
                          />
                        </Form.Group>

                        <Form.Group controlId="formBasicCountry">
                          <Form.Control
                            as="select"
                            value={selectedCountry}
                            onChange={handleCountryChange}
                            name="country"
                          >
                            <option value="" disabled>
                              Select Country
                            </option>
                            <option value="U.S.A"> U.S.A </option>
                            <option value="Canada">Canada</option>
                          </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="formBasicState" className="mt-2">
                          <Form.Control
                            as="select"
                            value={selectedState}
                            onChange={handleStateChange}
                            name="state"
                          >
                            <option value="" disabled>
                              Select State
                            </option>
                            {selectedCountry === "U.S.A" &&
                              usaStates
                                .slice()
                                .sort((a, b) =>
                                  a.Statename.localeCompare(b.Statename)
                                )
                                .map((item, index) => (
                                  <option key={index} value={item.Statename}>
                                    {item.Statename}
                                  </option>
                                ))}
                            {selectedCountry === "Canada" &&
                              canadaStates
                                .slice()
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map((item, index) => (
                                  <option key={index} value={item.name}>
                                    {item.name}
                                  </option>
                                ))}
                          </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="formBasicTerms">
                          <Form.Check
                            type="checkbox"
                            label={
                              <>
                                I agree to the{" "}
                                <Link to="/termsofuse">
                                  terms and conditions
                                </Link>
                              </>
                            }
                            checked={isTermsChecked}
                            onChange={handleTermsCheckboxChange}
                            style={{ textAlign: "left" }}
                          />
                        </Form.Group>
                        <Button
                          className="form-control btn mt-2"
                          type="submit"
                          disabled={!isTermsChecked}
                        >
                          Sign Up
                        </Button>

                        <div className="form-links">
                          <Link
                            onClick={() => {
                              handleClose();
                              openLoginModal();
                            }}
                            className="small text-muted"
                          >
                            Already have an account? Login here
                          </Link>
                        </div>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <ToastContainer />
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default Sign;
