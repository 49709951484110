import Banner from "./Banner";
import Category from "./Category";
import Clients from "./Clients";
import Connected from "./Connected";
import Map from "./Map";
import Oursales from "./Oursales";
import Simplesteps from "./Simplesteps";
import Support from "./Support";
import Testimonials from "./Testimonials";
import Vippremiumnumbers from "./Vippremiumnumbers";
import Whynumberkarma from "./Whynumberkarma";

function Home() {
    return (  
        <>
            <Banner />
            <Map />
            <Clients />
            <Oursales />
            <Category />
            <Vippremiumnumbers />
            <Whynumberkarma />
            <Simplesteps />
            <Testimonials />
            <Support />
            <Connected />
           
        </>
    );
}

export default Home;