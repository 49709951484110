import React, { useEffect, useState } from 'react';
import { PayPalButton } from 'react-paypal-button-v2';
import "bootstrap/dist/css/bootstrap.min.css";
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-bootstrap';


const PayPalComponent = ({amount, productid}) => {
    const storedAuthorId = sessionStorage.getItem("authorid");
  const [formData, setFormData] = useState({
    productid: '',
    createdby: '',
    amount: '',
  });
  useEffect(() => {
    setFormData({
      productid: productid,
      createdby: storedAuthorId,
      amount: amount,
    });
  }, [amount, productid, storedAuthorId]);
//    const onSuccess = (details, data) => {
//     // Handle the payment success
    //  console.log('Payment completed', details, data);
//     
//     // Assuming you have the necessary data to populate the formData state
//     setFormData({
//       productid: productid,
//       createdby: storedAuthorId,
//       amount: amount, // You need to replace 'your_amount' with the actual amount
//     });
//    };

  const onCancel = (data) => {
    // Handle payment cancellation
    console.log('Payment cancelled', data);
  };

  const onError = (err) => {
    // Handle errors
    console.error('Error during payment', err);
  };

  const createBill = async () => {
    debugger
    const url = 'https://vanityapi.adef.tech/Cart/CreateBill';

    const dataReceived = new URLSearchParams(formData);
    
    try {
      const response = await fetch(url, {
        method: 'POST',
        body: dataReceived,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      
      const responseData = await response.json();
      if(responseData.responseCode === 1){
        const showSuccessToast = () => {
          toast.success('Payment successful! Your order has been placed successfully. You will be notified shortly.', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            onClose: () => {
              // Callback function to be executed when the toast is closed
              window.location = "/";
              sessionStorage.removeItem("cartId");
            }
          });
        };
        showSuccessToast();
      }
      else{
        const showErrorToast = () => {
          toast.error(responseData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        };
        showErrorToast();
      }
      // Handle the responseData as needed
      console.log('CreateBill response:', responseData);
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  return (
    <>
    <div className='d-flex justify-content-center py-5'>
        
      <PayPalButton
        //  amount={100}
         amount={amount}
        // Other options like currency, onSuccess, onCancel, onError, etc.
        onSuccess={(details, data) => {
            // onSuccess(details, data);
            createBill();
          }}
        onCancel={onCancel}
        onError={onError}
        options={{
           // live
            clientId: 'ARkAwgblSGgWAqLmFzq26yZmFXd8YgJK4ep0BFaxnQxWzG-hwJGpE3zIbBeuX6HXePQFzl-sgscUh5nK',
            // test
            // clientId: "AX-ROSkCxK1W1uwSJFxl2nwe5Q2TJMmmYYZNnhHXSEDO7cgh8CvogLUirE1um46FrTMYcdxjMQEqe3VM",
           
          currency: 'USD',
          custom: JSON.stringify(formData)
        }}
      />

    </div>
              <ToastContainer></ToastContainer>
              </>
  );
};

export default PayPalComponent;