// TimerContext.js
import { createContext, useContext, useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const TimerContext = createContext();

const TimerProvider = ({ children }) => {
  const storedTimerState = JSON.parse(localStorage.getItem('timerState')) || {
    showTimer: false,
    minutes: 0,
    seconds: 0,
  };

  const [showTimer, setShowTimer] = useState(storedTimerState.showTimer);
  const [minutes, setMinutes] = useState(storedTimerState.minutes);
  const [seconds, setSeconds] = useState(storedTimerState.seconds);

  useEffect(() => {
    let countdownInterval;
  
    if (showTimer && minutes >= 0 && seconds >= 0) {
      countdownInterval = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds === 0) {
            if (minutes === 0) {
              // Timer completed, stop the timer
              setShowTimer(false);
              clearInterval(countdownInterval);
              // Add your logic for timer completion here if needed
            } else {
              // Move to the previous minute
              setMinutes((prevMinutes) => prevMinutes - 1);
              return 59;
            }
          } else {
            return prevSeconds - 1;
          }
        });
      }, 1000);
    }
  
    return () => {
      clearInterval(countdownInterval);
    };
  }, [showTimer, minutes, seconds]);

  const startTimer = (initialMinutes, initialSeconds) => {
    setShowTimer(true);
    setMinutes(initialMinutes);
    setSeconds(initialSeconds || 59); // Updated to start from 59 seconds
  };

  const timerValues = { showTimer, minutes, seconds, setShowTimer, startTimer };
  useEffect(() => {
    // Code to run after the timer completes
    if (showTimer && minutes === 0 && seconds === 0) {
      // Add your logic here
      console.log('Timer completed! Add your logic here.');
      const cartid = sessionStorage.getItem("cartId");
      const cartDelete = (id) => {
        if (id) {
          const formData = { id: id};
          const data = new URLSearchParams(formData);
    
          fetch("https://vanityapi.adef.tech/Cart/Delete", {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: data
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
              }
              return response.json();
            })
            .then((data) => {
              if (data.responseCode === 1) {
                const showSuccessToast = () => {
                  toast.success("Cart item deleted.", {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                  });
                };
                showSuccessToast();
                window.location = "/";
              }
              else{
                  const showErrorToast = () => {
                    toast.error(data.responsemessage, {
                      position: 'top-right',
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                    });
                  };
                  showErrorToast();
              }
            })
            .catch((error) => {
              console.error("API Error:", error);
            });
        }
      };
      cartDelete(cartid);
    }
      // Store timer state in local storage
    localStorage.setItem('timerState', JSON.stringify({ showTimer, minutes, seconds }));

  }, [showTimer, minutes, seconds]);
  return (
    <>
          <ToastContainer />
          <TimerContext.Provider value={timerValues}>{children}</TimerContext.Provider>
    </>
  )
};

const useTimer = () => {
  const context = useContext(TimerContext);
  if (!context) {
    throw new Error('useTimer must be used within a TimerProvider');
  }
  return context;
};

export { TimerProvider, useTimer };
