import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ToastContainer } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const CustomNumber = ({ onClose }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    state: "",
    country: "",
    currentPhoneNumber: "",
    requiredPhoneNumbers: "",
    priceRange: "",
    requirementExplanation: "",
  });
  const [usaStates, setUsaStates] = useState([]);
  const [canadaStates, setCanadaStates] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handlePhoneChange = (value, name) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleStateChange = (e) => {
    const value = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      state: value,
    }));
  };

  const handleCountryChange = async (e) => {
    const value = e.target.value;
    setSelectedCountry(value);
    setFormData((prevData) => ({
      ...prevData,
      country: value,
    }));

    // Fetch states based on the selected country
    try {
      let response;
      let data;

      if (value === "U.S.A") {
        response = await fetch("https://vanityapi.adef.tech/Product/StateList");
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        data = await response.json();
        setUsaStates(data);
      } else if (value === "Canada") {
        response = await fetch("https://vanityapi.adef.tech/Home/canadastates");
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        data = await response.json();
        setCanadaStates(data);
      }
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      
      const dataReceived = new URLSearchParams(formData);

      // Make an HTTP request using fetch
      const response = await fetch(
        "https://vanityapi.adef.tech/Review/SendEnquiryForCustomNumber",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: dataReceived,
        }
      );

      // Check if the request was successful (status code in the range 200-299)
      if (response.ok) {
        const responseData = await response.json();
        
        const showSuccessToast = () => {
          toast.success(responseData, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            onClose: () => {
              onClose();
            },
          });
        };
        showSuccessToast();
      } else {
        // Handle non-successful responses (e.g., show error message)
        console.error("Error submitting form:", response.statusText);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error, show error message, etc.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-3">
        <label htmlFor="name" className="form-label">
          First Name
        </label>
        <input
          type="text"
          className="form-control"
          id="firstName"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="name" className="form-label">
          Last Name
        </label>
        <input
          type="text"
          className="form-control"
          id="lastName"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          required
        />
      </div>

      <div className="mb-3">
        <label htmlFor="email" className="form-label">
          Email
        </label>
        <input
          type="email"
          className="form-control"
          id="emailAddress"
          name="emailAddress"
          value={formData.emailAddress}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="country" className="form-label">
          Country
        </label>
        <select
          className="form-select"
          id="country"
          name="country"
          value={formData.country}
          onChange={handleCountryChange}
          required
        >
          <option value="" disabled>
            Select Country
          </option>
          <option value="U.S.A"> U.S.A </option>
          <option value="Canada">Canada</option>
        </select>
      </div>
      <div className="mb-3">
        <label htmlFor="state" className="form-label">
          State
        </label>
        <select
          className="form-select"
          id="state"
          name="state"
          value={formData.state}
          onChange={handleStateChange}
          required
        >
          <option value="" disabled>
            Select State
          </option>
          {selectedCountry === "U.S.A" &&
            usaStates
              .slice()
              .sort((a, b) => a.Statename.localeCompare(b.Statename))
              .map((item, index) => (
                <option key={index} value={item.Statename}>
                  {item.Statename}
                </option>
              ))}
          {selectedCountry === "Canada" &&
            canadaStates
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((item, index) => (
                <option key={index} value={item.name}>
                  {item.name}
                </option>
              ))}
        </select>
      </div>
      <div className="mb-3">
        <label htmlFor="currentPhoneNumber" className="form-label">
          Current Phone Number
        </label>
        <PhoneInput
          defaultCountry={"us"} // Default country
          enableAreaCodes={true}
          disableCountryCode={true} // Hide country code
          value={formData.currentPhoneNumber}
          onChange={(value) => handlePhoneChange(value, "currentPhoneNumber")}
          inputProps={{
            name: "currentPhoneNumber",
          }}
        />
      </div>

      <div className="mb-3">
        <label htmlFor="requiredPhoneNumbers" className="form-label">
          Required Phone Number
        </label>
        <PhoneInput
          defaultCountry={"us"} // Default country
          enableAreaCodes={true}
          disableCountryCode={true} // Hide country code
          value={formData.requiredPhoneNumbers}
          onChange={(value) => handlePhoneChange(value, "requiredPhoneNumbers")}
          inputProps={{
            name: "requiredPhoneNumbers",
          }}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="priceRange" className="form-label">
          Preferred Price Range
        </label>
        <input
          type="text"
          className="form-control"
          id="priceRange"
          name="priceRange"
          value={formData.priceRange}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="requirementExplanation" className="form-label">
          Requirement
        </label>
        <textarea
          className="form-control"
          id="requirementExplanation"
          name="requirementExplanation"
          value={formData.requirementExplanation}
          onChange={handleChange}
          required
        ></textarea>
      </div>

      <button type="submit" className="btn btn-primary">
        Submit
      </button>
      <ToastContainer></ToastContainer>
    </form>
  );
};

export default CustomNumber;
