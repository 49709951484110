import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'aos/dist/aos.css';
import AOS from 'aos';
import './Simplesteps.css';

function Simplesteps() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
    AOS.refresh();
  }, []);

  return (
    <Container>
      <Row>
        {/* Centered column with three tabs */}
        <Col md={8} className="d-flex flex-column justify-content-center text-center">
          <p className="card-title font-weight-bold mt-4 karmaheading">3 SIMPLE STEPS</p>
          <div className="tab p-3 my-2 rounded bg-light">
            <div className="d-flex align-items-center">
              <i className="fas fa-search mr-2"></i>
              <span>Test-drive our unique search tool to find the best phone number for your business.</span>
            </div>
          </div>
          <div className="tab p-3 mb-2 rounded bg-light">
            <div className="d-flex align-items-center">
              <i className="fas fa-search mr-2"></i>
              <span>Our straightforward checkout process allows you to quickly get your number.</span>
            </div>
          </div>
          <div className="tab p-3 mb-2 rounded bg-light">
            <div className="d-flex align-items-center">
              <i className="fas fa-search mr-2"></i>
              <span>Easily set up and start using your new phone within few days.</span>
            </div>
          </div>
        </Col>

        {/* Centered column with a smaller image */}
        <Col md={4} className="d-flex justify-content-center align-items-center my-5">
          <img
            src="/images/rectangle-119.png"
            alt="Your "
            className="img-fluid rounded"
          />
        </Col>
      </Row>
    </Container>
  );
}

export default Simplesteps;
