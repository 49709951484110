// Porting.js

import React from 'react';
import './Porting.css';
import { Container } from 'react-bootstrap';

const Porting = () => {
    return (
        <Container fluid className='portingcontainer p-2'>
        <div className="porting-container mt-4">
            <div className="porting-item">
                <h3>What does "port my number" mean?</h3>
                <p>"Porting" simply means transferring your existing landline or cell phone number from one telephone service provider to another.</p>

            </div>

            <div className="porting-item">
                <h3>Who owns my phone number after porting?</h3>
                <p>You are the Owner of your Number.</p>
            </div>

            <div className="porting-item">
                <h3>How long will porting take?</h3>
                <p>Porting will take as little as 3 days, and as long as 30 days. Most wireless numbers will port in 3 business days; landlines will typically port in 7 business days from major carriers, and 10-20 business days from smaller carriers. Don't worry, we'll keep you updated throughout the porting process, and we'll let you know once we have agreed on a firm porting date with your current carrier.
                </p>
            </div>

            <div className="porting-item">
                <h3>What information is needed to port my number?</h3>
                <p>
                    The information needed to port your number will be collected during sign up. That information includes: the number you are porting, your account number and PIN#/Passcode (when porting a wireless number), and your physical address (no PO Boxes). Please make sure the information you submit during sign up matches the information your current provider has on file. Any discrepancies can delay the number porting process.
                </p>
            </div>

            <div className="porting-item">
                <h3>What is the verification process for transfers? Do I need a current bill?</h3>
                <p>For wireless numbers, we will require your wireless account number and PIN. Note that the account number is often different from your wireless phone number. Once we verify your order, we'll send you an SMS or call you to verify that the porting request is authorized. Once that's done, we'll work with your existing carrier to port your number as quickly as possible.
                   For landline numbers, we will call you to verify that the porting request is authorized. Once we verify your order, we'll work with your existing carrier to port your number as quickly as possible. Some carriers will request a copy of your recent phone bill, or other verification information, and if this happens, we'll work with you to provide that information to the carrier and complete the porting process.
                </p>
            </div>
        </div>
        </Container>
    );
};

export default Porting;
