// LocalNumber.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import "./Localnumbers.css";  // Import the new CSS file

function LocalNumber() {
  const [localNumbers, setLocalNumbers] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios
      .get('https://vanityapi.adef.tech/Home/industrytypeDropdown')
      .then(response => {
        setLocalNumbers(response.data);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
        setError(error);
      });
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const localNumberList = localNumbers.map(item => (
    <Col md={3} key={item.id}>
      <Link className='local-industry-info text-decoration-none text-center' to={`/industry/${item.industryname}`}>
        <div className='local-industry-card '>
          <div className='local-image-container'>
            <img src={item.image} alt={item.industryname} />
          </div>
          <div className='local'>
            <p className='local-number'>{item.industryname}</p>
          </div>
        </div>
      </Link>
    </Col>
  ));

  return (
    <Container fluid className='localNumbersPage'>
      <Row className='mt-5'>
        <Col md={4}></Col>
        <Col md={12} className='text-center'>
          <h2>Industry Numbers List</h2>
        </Col>
        <Col md={4}></Col>
      </Row>
      <Container>
        <Row className='mt-4'>{localNumberList}</Row>
      </Container>
    </Container>
  );
}

export default LocalNumber;
