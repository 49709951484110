import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { TfiArrowCircleRight } from 'react-icons/tfi';
import "../Components/Category.css";

const Category = () => {
  return (
    <section className='browersection'>
      <Container >
      <Row className="mt-5">
        <Col md={12}>
        <p className="px-0 py-2 m-0 sideheading whitetext"> Browse Through</p>
          <hr className='whitetext'></hr>
        </Col>
      </Row>      
    </Container>
      <Container>
        <h2 className='text-center middleheading whitetext pt-4'>easily search by category </h2>
        <Row className="">
            <Col md={4} className="text-center px-4 my-5">
              <div className="card">
                <div className="icon-container">
                  <img
                    src="../images/rectangle-110.png"
                    alt="Icon"
                    className="icon-image py-5"
                  />
                </div>
                <div className="card-body d-flex mt-3 justify-content-between text-center">
                  <h5 className="card-title">Numbers By Industry</h5>
                    <div className="button-container">
                      <div className="click-here-container">
                        <a href="/show" className="btn-primary-rounded-circle">
                            <TfiArrowCircleRight className='arrow-button' />
                        </a>
                      </div>
                  </div>
              </div>
              </div>
            </Col>
            <Col md={4} className="text-center px-4 my-5">
              <div className="card">
                <div className="icon-container">
                  <img
                    src="../images/rectangle-111.png"
                    alt="Icon"
                    className="icon-image py-5"
                  />
                </div>
                <div className="card-body d-flex mt-3 justify-content-between text-center">
                  <h5 className="card-title">Numbers by Pattern </h5>
                    <div className="button-container">
                      <div className="click-here-container">
                        <a href="/pattern" className="btn-primary-rounded-circle">
                            <TfiArrowCircleRight className='arrow-button' />
                        </a>
                      </div>
                  </div>
              </div>
              </div>
            </Col>
            <Col md={4} className="text-center px-4 my-5">
              <div className="card">
                <div className="icon-container">
                  <img
                    src="../images/rectangle-112.png"
                    alt="Icon"
                    className="icon-image py-5"
                  />
                </div>
                <div className="card-body d-flex mt-3 justify-content-between text-center">
                  <h5 className="card-title"> Numbers by State</h5>
                    <div className="button-container">
                      <div className="click-here-container">
                        <a href="/state" className="btn-primary-rounded-circle">
                            <TfiArrowCircleRight className='arrow-button' />
                        </a>
                      </div>
                  </div>
              </div>
              </div>
            </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Category;
