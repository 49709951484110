import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { ToastContainer } from "react-bootstrap";

const CallbackRequestForm = ({ onClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    country: "us",
    contactPhoneNumber: "",
    requirement: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handlePhoneChange = (value, data, name) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      country: data ? data.country || prevData.country : prevData.country,
      // Keep the current country if data is undefined or not provided
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const dataReceived = new URLSearchParams(formData);

      // Make an HTTP request using fetch
      const response = await fetch(
        "https://vanityapi.adef.tech/Review/RequestCallback",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: dataReceived,
        }
      );

      // Check if the request was successful (status code in the range 200-299)
      if (response.ok) {
        const responseData = await response.json();

        const showSuccessToast = () => {
          toast.success(responseData, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            onClose: () => {
              onClose();
            },
          });
        };
        showSuccessToast();
      } else {
        // Handle non-successful responses (e.g., show error message)
        console.error("Error submitting form:", response.statusText);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error, show error message, etc.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-3">
        <label htmlFor="name" className="form-label">
          Name
        </label>
        <input
          type="text"
          className="form-control"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />
      </div>

      <div className="mb-3">
        <label htmlFor="email" className="form-label">
          Email
        </label>
        <input
          type="email"
          className="form-control"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>

      <div className="mb-3">
        <label htmlFor="contactPhoneNumber" className="form-label">
          Contact Phone Number
        </label>
        <PhoneInput
          defaultCountry={formData.country}
          enableAreaCodes={true}
          disableCountryCode={true}
          value={formData.contactPhoneNumber}
          onChange={(value, data) =>
            handlePhoneChange(value, data, "contactPhoneNumber")
          }
          inputProps={{
            name: "contactPhoneNumber",
          }}
          countryOptions={[
            "us", // Add other country codes as needed
          ]}
          // Specify the custom format based on the country
          format={(value, country) => {
            // Customize the format based on the selected country
            return country === "us" ? "+1 (XXX) XXX-XXXX" : "+XX XXXX-XXXXX";
          }}
        />
      </div>

      <div className="mb-3">
        <label htmlFor="requirement" className="form-label">
          Requirement
        </label>
        <textarea
          className="form-control"
          id="requirement"
          name="requirement"
          value={formData.requirement}
          onChange={handleChange}
          required
        ></textarea>
      </div>

      <button type="submit" className="btn btn-primary">
        Submit
      </button>
      <ToastContainer></ToastContainer>
    </form>
  );
};

export default CallbackRequestForm;
