import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const SupportEnquiryForm = ({ onClose }) =>
{
  
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contactPhoneNumber: '',
    subject: '',
    question: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handlePhoneChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      contactPhoneNumber: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const dataReceived = new URLSearchParams(formData);

      // Make an HTTP request using fetch
      const response = await fetch('https://vanityapi.adef.tech/Review/SendEnquiryForSupport', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: dataReceived,
      });

      // Check if the request was successful (status code in the range 200-299)
      if (response.ok) {
        const responseData = await response.json();

        const showSuccessToast = () => {
          toast.success(responseData, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            onClose: () => {
              onClose();
            },
          });
        };
        showSuccessToast();
      } else {
        // Handle non-successful responses (e.g., show error message)
        console.error('Error submitting form:', response.statusText);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      // Handle error, show error message, etc.
    }
  };

  

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-3">
        <label htmlFor="name" className="form-label">
          Name
        </label>
        <input
          type="text"
          className="form-control"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />
      </div>

      <div className="mb-3">
        <label htmlFor="email" className="form-label">
          Email
        </label>
        <input
          type="email"
          className="form-control"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>

      <div className="mb-3">
        <label htmlFor="contactPhoneNumber" className="form-label">
          Contact Phone Number
        </label>
        <PhoneInput
          defaultCountry={'us'} // Default country
          enableAreaCodes={true}
          disableCountryCode={true} // Hide country code
          value={formData.contactPhoneNumber}
          onChange={handlePhoneChange}
          inputProps={{
            name: 'contactPhoneNumber',
          }}
        />
      </div>

      <div className="mb-3">
        <label htmlFor="subject" className="form-label">
          Subject
        </label>
        <input
          type="text"
          className="form-control"
          id="subject"
          name="subject"
          value={formData.subject}
          onChange={handleChange}
          required
        />
      </div>

      <div className="mb-3">
        <label htmlFor="question" className="form-label">
          Question
        </label>
        <textarea
          className="form-control"
          id="question"
          name="question"
          value={formData.question}
          onChange={handleChange}
          required
        ></textarea>
      </div>

      <button type="submit" className="btn btn-primary">
        Submit
      </button>
      <ToastContainer></ToastContainer>
    </form>
  );
};

export default SupportEnquiryForm;
