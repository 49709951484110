import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import logoURL from "../IMG_0169.JPG";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from "./Login";
import Sign from "./Sign";
// import "./ForgotPassword.css"; // You can create a separate CSS file

function ForgotPassword({openLoginModal}) {
  const [email, setEmail] = useState("");
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSignUpModal, setShowSignUpModal] = useState(false);

  const handleShowLoginModal = () => setShowLoginModal(true);
  const handleCloseLoginModal = () => setShowLoginModal(false);

  const handleShowSignUpModal = () => setShowSignUpModal(true);
  const handleCloseSignUpModal = () => setShowSignUpModal(false);
  const handleForgotPassword = async (e) => {
    e.preventDefault();

    const url = "https://vanityapi.adef.tech/Customer/ForgotPasswordRequest";
    
    const data = new URLSearchParams({
      email,
    });

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      const responseData = await response.json();
      if (responseData.responseCode === 1) {
        const showSuccessToast = () => {
          toast.success(responseData.responsemessage, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            onClose: ()=>{ window.location='/otp?email=' + email }
          });
        };
        showSuccessToast();
        // You can add further logic like redirecting to a confirmation page
      } else {
        const showErrorToast = () => {
          toast.error(responseData.responsemessage, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        };
        showErrorToast();
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  return (
    <>
      <Container fluid className="custom-container">
        <Row className="d-flex justify-content-center login">
          <Col md={10}>
            <div className="mt-5 mb-5">
              <Row>
                <Col md={3}>
                  {/* Add your image or any additional content for the forgot password page */}
                </Col>
                <Col md={6} className="login-form">
                  <div className="form-wrapper">
                    <img src={logoURL} alt="Logo" className="logo d-flex m-auto" />

                    <Form className="login-pages" onSubmit={handleForgotPassword}>
                      <Form.Group controlId="formBasicEmail p-9">
                        <Form.Control
                          type="email"
                          placeholder="Enter your email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Form.Group>

                      <Button className="form-control btn" type="submit" block>
                        Send OTP
                      </Button>

                      <div className="form-links">
                        <Link className="small text-muted" onClick={handleShowLoginModal}>
                                                      Back to Login
                        </Link>
                        <Login show={showLoginModal} handleClose={handleCloseLoginModal} openSignUpModal={handleShowSignUpModal} />
                              <Sign show={showSignUpModal} handleClose={handleCloseSignUpModal} openLoginModal={handleShowLoginModal} />

                      </div>
                    </Form>
                  </div>
                </Col>
                <Col md={3}>
                  {/* Add your image or any additional content for the forgot password page */}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </>
  );
}

export default ForgotPassword;
