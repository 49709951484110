import React from 'react';
import './FAQS.css'; // Import your CSS file
import { Container } from 'react-bootstrap';

const ProcessFAQ = () => {
    return (
        <Container fluid className='faqs-container p-2'>
        <div className="faq-container mt-4">
            <div className="faq-item">
                <h3>Porting Away from NumberKarma</h3>
                <b>Can I port my number out from NumberKarma?</b>
                <p>
                Yes! You can port your number out to the carrier of your choice at any time. There are no restrictions or fees to do so.
                </p>
                <b>Is there a fee to port my number away from NumberKarma?</b>
                <p>
                    NumberKarma does not charge a port out fee. Your new provider will probably charge a porting fee. You will still be responsible for paying for your monthly service until the number port completes.

                </p>
                <b>Will my new carrier accept a phone number transferred from NumberKarma?</b>
                <p>The majority of carriers will accept phone number transfers from NumberKarma. Some mobile carriers have policies that only allow them to port in "wireless" phone numbers. NumberKarma's numbers are classified as "wireline" and that classification can not be modified by NumberKarma. Check with your new carrier first</p>
            </div>

            <div className="faq-item">
                <b>Can I port out a number to any phone?</b>
                <p>
                    Cellular phones and VoIP can be used with any number. However, landlines must be associated with an area code for the region it is in. So while you can use a California 951 area code for a cellphone or VOIP carrier in New York (as an example), you would need a local New York area code such as 646 for a landline.
                </p>
                <p>
                Please note: Not every phone number in your area code will work at every address in your area code. This is true of all numbers in the country. If you move outside of your “rate center”, even your current landline number would not work at your new address. It has to do with the legacy telecom switch network. VOIP and wireless carriers are not bound by these restrictions. They use the internet and cell towers, respectively, independent of the telecom switch network.
                </p>
                <p>
                Please contact your landline carrier and ask them if the number you are interested in will work at the physical address you want your phone number to work at before ordering or buying your new phone number to confirm.
                </p>
            </div>

            <div className="faq-item">
                <b>What is a rate center?</b>
                <p>
                    A rate center is a geographically specific area used to determine the boundaries for local calling, billing and assigning phone numbers. Typically a call within a rate center is local, while a call from one rate center to another is a long-distance call. Rate center boundaries do not necessarily align with county or municipal boundaries.
                </p>
                <b>Can I port my toll free number to my wireless carrier for use on my cell phone?</b>
                <p>No. Toll free numbers can not be ported to a wireless carrier. Toll free numbers can only be ported to a landline or VoIP carrier.</p>
            </div>
            <div className="faq-item">
                <h3>What is VIP Phone Number?</h3>
                <p>
                    A VIP phone number is a special type of telephone number that is assigned to individuals or businesses who are considered to be of high importance. These numbers are typically considered premium numbers, and they are often used by celebrities, politicians, and other public figures who need to keep their phone number confidential.
                </p>
            </div>

            <div className="faq-item">
                <b>How do I port a number away from NumberKarma?</b>
                <p>
                When you purchase a number, your NumberKarma account will have the information you will need to port the phone number to your new carrier. You will need to provide this account information to the new carrier to port to their service.
                </p>
                <b>How long does porting out take?</b>
                <p>
                Port out timing depends on the carrier or service provider. They all tend to work at different speeds. Usually it takes about 3 - 7 business days for the process to complete.
                </p>
                <b>How will I know my number ported out?</b>
                <p>NumberKarma will send you an email notification letting you know we received a port out request and another when the number ports away.</p>
            </div>

            <div className="faq-item">
                <b>I'm porting away, do I need to select a monthly plan?</b>
                <p>
                    All numbers will need to be activated to port out to a new carrier. Selecting our Port Away plan allows the number to be activated on our system for porting. It is the least expensive and allows you 30 days to port away without paying any additional monthly fees. Once the number has ported away, your NumberKarma service is automatically cancelled and you will not incur any further charges.
                </p>
            </div>
        </div>
        </Container>
    );
};

export default ProcessFAQ;
