import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Constants from "./Constants";
import AddressList from './AddressList'; // Update the path accordingly

function ManageAddress({ handleLogout }) {

  const Project_Id = Constants.PROJECTID;

  const storedAuthorId = sessionStorage.getItem('authorid');

  const [formData, setFormData] = useState({
    firstname: "",
    phone: "",
    address: "",
    pincode: "",
    city:"",
    state: "",
    project_id: Project_Id,
    authorId:storedAuthorId,
    isprimary:true
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleBillAddress = async (e) => {
    e.preventDefault();
    const url = 'https://vanityapi.adef.tech/Addressbook/Create';

    const data = new URLSearchParams(formData);
    try {
      const response = await fetch(url, {
        method: 'POST',
        body: data,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      alert(response.responsemessage);

      const responseData = await response.json();

      if (responseData.message) {

      }
      // You can add further actions or notifications here
    } catch (error) {
      console.error("Error Adding:", error);
      // You can display an error message to the user
    }
  
  };

  return (
    <div>
      <h2>Manage Address</h2>
      
      <AddressList Project_Id={Project_Id} Authkey={storedAuthorId} />
      <Form onSubmit={handleBillAddress}>
            <Row>
              <h3>Add new address</h3>
              <Col md={6}>
                <Form.Group controlId="formBasicName">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Full Name"
                    name="firstname"
                    value={formData.firstname}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formMobile">
                  <Form.Label>Mobile Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Mobile Number"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                {/* <Form.Group controlId="formCountry">
                  <Form.Label>Country / Region</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Country"
                    name="country"
                    value={formData.country}
                    onChange={handleInputChange}
                  />
                </Form.Group> */}
              </Col>
              <Col md={6}></Col>
              <Col md={6}>
                <Form.Group controlId="formZip">
                  <Form.Label>Zip Code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Zip Code"
                    name="pincode"
                    value={formData.pincode}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}></Col>
              <Col md={12}>
                <Form.Group controlId="formAddress">
                  <Form.Label>Address:</Form.Label>
                  <Form.Control
                    type="textarea"
                    placeholder="Enter your Adddress"
                    name="address"
                    value={formData.address}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group controlId="formCity">
                  <Form.Label>City / Town</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your City"
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formCountry">
                  <Form.Label>State / Region</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your State / Region"
                    name="state"
                    value={formData.state}
                    onChange={handleInputChange}
                  />
                  <p>Note: This will be your Billing address.</p>
                </Form.Group>
              </Col>
              <Col md={6}>
                
                    <Button className="form-control btn" type="submit">
                      Save
                    </Button>
                  
              </Col>
            </Row>
          </Form>
    </div>
  );
}

export default ManageAddress;
