import React, { useState } from "react";
import {
  Modal,
  Col,
  Container,
  Row,
  Navbar,
  Nav,
  NavDropdown,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../IMG_0169.JPG";
import "../index.css";
import "../Components/Header.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { CgProfile } from "react-icons/cg";

import Constants from "./Constants";
import ParkingPlanModal from "./ParkingPlanModal";
import Login from "./Login";
import Sign from "./Sign";
import CallbackRequestForm from "./CallbackRequestForm";
import NumberEnquiryForm from "./NumberEnquiryForm";
import SupportEnquiryForm from "./SupportEnquiryForm";
import BrokerageServices from "./BrokerageServices";
import CustomNumber from "./CustomNumber";

function Header() {
  const Project_Id = Constants.PROJECTID;
  const [cartCounter, setCartCounter] = useState(0);
  const cartid = sessionStorage.getItem("cartId");
  const [isModalOpen, setModalOpen] = useState(false);
  // Add state for controlling the menu visibility
  const [menuOpen, setMenuOpen] = useState(false);

  // Function to close the menu
  const closeMenu = () => {
    setMenuOpen(false);
  };
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSignUpModal, setShowSignUpModal] = useState(false);

  const handleShowLoginModal = () => setShowLoginModal(true);
  const handleCloseLoginModal = () => setShowLoginModal(false);

  const handleShowSignUpModal = () => setShowSignUpModal(true);
  const handleCloseSignUpModal = () => setShowSignUpModal(false);

  const [showCallbackModal, setShowCallbackModal] = useState(false);
  const [showSupportEnquiry, setShowSupportEnquiry] = useState(false);
  const [showNumberEnquiry, setShowNumberEnquiry] = useState(false);
  const [showBrokerage, setShowBrokerage] = useState(false);
  const [showCustomNumber, setShowCustomNumber] = useState(false);

  const handleShowCallbackModal = () => {
    // Set the state to true to show the modal
    setShowCallbackModal(true);
    setShowSupportEnquiry(false);
    setShowNumberEnquiry(false);
    console.log("Opening callback modal");
  };

  const handleCloseCallbackModal = () => {
    // Set the state to false to hide the modal
    setShowCallbackModal(false);
  };

  const handleShowSupportEnquiry = () => {
    setShowCallbackModal(false);
    setShowSupportEnquiry(true);
    setShowNumberEnquiry(false);
  };

  const handleCloseSupportEnquiry = () => {
    setShowSupportEnquiry(false);
  };

  const handleShowNumberEnquiry = () => {
    setShowCallbackModal(false);
    setShowSupportEnquiry(false);
    setShowNumberEnquiry(true);
  };

  const handleCloseNumberEnquiry = () => {
    setShowNumberEnquiry(false);
  };

  const handleShowBrokerage = () => {
    setShowBrokerage(true);
    // Close other modals if needed
  };

  const handleCloseBrokerage = () => {
    setShowBrokerage(false);
  };

  const handleShowCustomNumber = () => {
    setShowCustomNumber(true);
    // Close other modals if needed
  };

  const handleCloseCustomNumber = () => {
    setShowCustomNumber(false);
  };

  const openModal = () => {
    setModalOpen(true);
  };
  var cartcount = sessionStorage.getItem("cartcount");
  if (cartcount === 1) {
    setCartCounter(1);
  }
  const closeModal = () => {
    setModalOpen(false);
  };
  if (cartid) {
    fetch(
      `https://vanityapi.adef.tech/Cart/Index?project_id=${Project_Id}&cart_id=${cartid}`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.length > 0 && data !== "Cart is empty") {
          setCartCounter(data.length); // Set the cart count
        } else {
          setCartCounter(0);
        }
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  }

  const authorid = sessionStorage.getItem("authorid");
  const authorname = sessionStorage.getItem("authorname");
  const [onSaleDropdownOpen, setOnSaleDropdownOpen] = useState(false);
  const [numberDropdownOpen, setNumberDropdownOpen] = useState(false);
  const [servicesDropdownOpen, setServicesDropdownOpen] = useState(false);
  const [processDropdownOpen, setProcessDropdownOpen] = useState(false);
  const [aboutDropdownOpen, setAboutDropdownOpen] = useState(false);
  const [supportDropdownOpen, setSupportDropdownOpen] = useState(false);

  const handleSignUpSuccess = () => {
    // Close the sign-up modal
    setShowSignUpModal(false);

    // Open the login modal
    handleShowLoginModal();
  };

  return (
    <>
      <header className="sticky-header">
        {/* <Container fluid className="topbar">
          <Row>
            <Col md={12}>
              <p>Sales for New Premium Number is now live. <a href="/numberbank" className="text-white">Buy Now</a></p>
            </Col>
          </Row>
        </Container> */}
        <Container fluid className="menubar">
          <Row>
            <Col md={12}>
              <Navbar expand="lg" className="p-0 my-auto">
                <Navbar.Brand href="/">
                  <img src={logo} alt="" height="50"></img>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse
                  id="basic-navbar-nav"
                  className={menuOpen ? "show" : ""}
                >
                  <Nav className="m-auto" onSelect={() => setMenuOpen(false)}>
                    <Nav.Link href="/numberBank">Number Bank</Nav.Link>
                    {/* <Nav.Link href="/onsale">On Sale</Nav.Link> */}

                    <NavDropdown
                      title="On Sale"
                      id="on-sale-dropdown"
                      className="plus-icon mega-menu onsale"
                      onMouseEnter={() => setOnSaleDropdownOpen(true)}
                      onMouseLeave={() => setOnSaleDropdownOpen(false)}
                      onClick={() => setOnSaleDropdownOpen(!onSaleDropdownOpen)}
                      show={onSaleDropdownOpen}
                    >
                      <Container>
                        <Row>
                          <Col md={4}>
                            <h6>By State</h6>
                            <NavDropdown.Item href="/filters?state=Oregon">
                              Oregon
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/filters?state=New York">
                              New York
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/filters?state=Maine">
                              Maine
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/filters?state=Oklahoma">
                              Oklahoma
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/filters?state=Alabama">
                              Alabama
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/state">
                              Show all
                            </NavDropdown.Item>
                          </Col>
                          <Col md={4}>
                            <h6>By Pattern</h6>
                            <NavDropdown.Item href="/filters?pattern=Unique & Rare">
                              Unique & Rare
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/filters?pattern=100’S or 1000’S (X00-Y000)">
                              100’S or 1000’S
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/filters?pattern=Set Repeater (XX ZZ XX)">
                              Set Repeater
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/filters?pattern=Rummy Pattern">
                              Rummy Pattern
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/filters?pattern=2 or 3 Digits Combination">
                              2 or 3 Digits Combination
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/pattern">
                              Show all
                            </NavDropdown.Item>
                          </Col>
                          {/*<Col md={3}>
                            <h6>By Industry</h6>
                            <NavDropdown.Item href="/industry/Sports%20Related">
                              Sports Related
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/industry/HealthCare">
                              HealthCare
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/industry/Banking">
                              Banking
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/industry/Transportation">
                              Transportation
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/industries">
                              Show all
                            </NavDropdown.Item>
                          </Col> */}
                          <Col md={4}>
                          <h6>Sales</h6>
                            <NavDropdown.Item href="/filters">
                            Numbers On Sale
                            </NavDropdown.Item>
                            <img
                              src="/images/hide.png"
                              alt=""
                              width="200"
                              className="img-fluid sticky_image"
                            ></img>
                          </Col>
                          <Col md={4}>
                           
                            {/* <h6>By Category</h6>
                            <NavDropdown.Item href="/category">
                            Show all
                            </NavDropdown.Item> */}
                          </Col>
                        </Row>
                      </Container>
                    </NavDropdown>
                    <NavDropdown
                      title="New Numbers"
                      id="new-numbers-dropdown"
                      className="plus-icon"
                      onMouseEnter={() => setNumberDropdownOpen(true)}
                      onMouseLeave={() => setNumberDropdownOpen(false)}
                      onClick={() => setNumberDropdownOpen(!numberDropdownOpen)}
                      show={numberDropdownOpen}
                    >
                      <NavDropdown.Item href="/local">
                        Local Numbers
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/VanityNumber">
                        Vanity numbers
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/vipnumbers">
                        V.I.P Numbers
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/tollfreenumbers">
                        Toll free Numbers
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/numberBank">
                        Number Search Engine
                      </NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown
                      title="Services"
                      id="services-dropdown"
                      className="plus-icon"
                      onMouseEnter={() => setServicesDropdownOpen(true)}
                      onMouseLeave={() => setServicesDropdownOpen(false)}
                      onClick={() =>
                        setServicesDropdownOpen(!servicesDropdownOpen)
                      }
                      show={servicesDropdownOpen}
                    >
                      <NavDropdown.Item href="/digitalcoldstorage">
                        Digital Cold Storage/Parking
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/brokerage">
                        Brokerage Service
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/virtualphonenumbers">
                        Virtual Phone Numbers
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/customnumbers">
                        Custom Numbers
                      </NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown
                      title="Process"
                      id="process-dropdown"
                      className="plus-icon"
                      onMouseEnter={() => setProcessDropdownOpen(true)}
                      onMouseLeave={() => setProcessDropdownOpen(false)}
                      onClick={() =>
                        setProcessDropdownOpen(!processDropdownOpen)
                      }
                      show={processDropdownOpen}
                    >
                      <NavDropdown.Item href="/process">
                        How does it work?
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/porting">
                        What is Porting?
                      </NavDropdown.Item>
                      {/* <NavDropdown.Item href="/processfaq">FAQ?</NavDropdown.Item> */}
                      <NavDropdown.Item href="/faqs">FAQ?</NavDropdown.Item>
                      <NavDropdown.Item href="/whynumberkarma">
                        Why Number Karma?
                      </NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown
                      title="About"
                      id="about-dropdown"
                      className="plus-icon"
                      onMouseEnter={() => setAboutDropdownOpen(true)}
                      onMouseLeave={() => setAboutDropdownOpen(false)}
                      onClick={() => setAboutDropdownOpen(!aboutDropdownOpen)}
                      show={aboutDropdownOpen}
                    >
                      <NavDropdown.Item href="/aboutus">
                        About Our Company{" "}
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/demandandsupply">
                        Demand & Supply
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/termsofuse">
                        Terms of Use
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/faqs">FAQ</NavDropdown.Item>

                      {/* <NavDropdown.Item href="/">Our Goals</NavDropdown.Item> */}
                    </NavDropdown>
                    <NavDropdown
                      title="Support"
                      id="support-dropdown"
                      className="plus-icon"
                      onMouseEnter={() => setSupportDropdownOpen(true)}
                      onMouseLeave={() => setSupportDropdownOpen(false)}
                      onClick={() =>
                        setSupportDropdownOpen(!supportDropdownOpen)
                      }
                      show={supportDropdownOpen}
                    >
                      {/* <NavDropdown.Item href="/">Live Chat </NavDropdown.Item> */}
                      <NavDropdown.Item onClick={handleShowCallbackModal}>
                        Request Call back
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={handleShowSupportEnquiry}>
                        Send Enquiry For Support
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={handleShowNumberEnquiry}>
                        Send Enquiry For Number
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={handleShowBrokerage}>
                        Send a Request for Brokerage Services
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={handleShowCustomNumber}>
                        Send a Request for Custom Number
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/faqs">FAQ</NavDropdown.Item>

                    </NavDropdown>
                    {showNumberEnquiry && (
                      <Modal
                        show={showNumberEnquiry}
                        onHide={handleCloseNumberEnquiry}
                        backdrop="static"
                        keyboard={false}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Number Enquiry</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          {/* Render the NumberEnquiryForm component */}
                          <NumberEnquiryForm
                            onClose={handleCloseNumberEnquiry}
                          />
                        </Modal.Body>
                      </Modal>
                    )}

                    {showCallbackModal && (
                      <Modal
                        show={showCallbackModal}
                        onHide={handleCloseCallbackModal}
                        backdrop="static"
                        keyboard={false}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Request Callback</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          {/* Render the CallbackRequestForm component */}
                          <CallbackRequestForm
                            onClose={handleCloseCallbackModal}
                          />
                        </Modal.Body>
                      </Modal>
                    )}

                    {showSupportEnquiry && (
                      <Modal
                        show={showSupportEnquiry}
                        onHide={handleCloseSupportEnquiry}
                        backdrop="static"
                        keyboard={false}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Support Enquiry</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          {/* Render the SupportEnquiryForm component */}
                          <SupportEnquiryForm
                            onClose={handleCloseSupportEnquiry}
                          />
                        </Modal.Body>
                      </Modal>
                    )}
                    {showBrokerage && (
                      <Modal
                        show={showBrokerage}
                        onHide={handleCloseBrokerage}
                        backdrop="static"
                        keyboard={false}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Brokerage Services</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          {/* Render the BrokerageServicesForm component */}
                          <BrokerageServices onClose={handleCloseBrokerage} />
                        </Modal.Body>
                      </Modal>
                    )}

                    {showCustomNumber && (
                      <Modal
                        show={showCustomNumber}
                        onHide={handleCloseCustomNumber}
                        backdrop="static"
                        keyboard={false}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Custom Number</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          {/* Render the CustomNumberForm component */}
                          <CustomNumber onClose={handleCloseCustomNumber} />
                        </Modal.Body>
                      </Modal>
                    )}
                  </Nav>
                  {/* Icons in menu */}
                  <Nav className="ms-auto">
                    {" "}
                    {/* Use ml-auto to right-align the Nav items */}
                    <Nav.Link href="/numberBank">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="26"
                        viewBox="0 0 26 26"
                        fill="none"
                      >
                        <path
                          d="M18.1667 18.1667L22.5 22.5M3 11.6667C3 13.9652 3.91309 16.1696 5.53841 17.7949C7.16372 19.4202 9.36812 20.3333 11.6667 20.3333C13.9652 20.3333 16.1696 19.4202 17.7949 17.7949C19.4202 16.1696 20.3333 13.9652 20.3333 11.6667C20.3333 9.36812 19.4202 7.16372 17.7949 5.53841C16.1696 3.91309 13.9652 3 11.6667 3C9.36812 3 7.16372 3.91309 5.53841 5.53841C3.91309 7.16372 3 9.36812 3 11.6667Z"
                          stroke="black"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Nav.Link>
                    <Nav.Link onClick={openModal} className="carticon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="26"
                        viewBox="0 0 26 26"
                        fill="none"
                      >
                        <path
                          d="M10.75 21.5C10.75 21.8214 10.6547 22.1356 10.4761 22.4028C10.2976 22.67 10.0438 22.8783 9.74686 23.0013C9.44993 23.1243 9.1232 23.1565 8.80798 23.0938C8.49276 23.0311 8.20321 22.8763 7.97595 22.649C7.74869 22.4218 7.59392 22.1322 7.53122 21.817C7.46852 21.5018 7.5007 21.1751 7.6237 20.8781C7.74669 20.5812 7.95497 20.3274 8.2222 20.1489C8.48943 19.9703 8.80361 19.875 9.125 19.875C9.55598 19.875 9.9693 20.0462 10.274 20.351C10.5788 20.6557 10.75 21.069 10.75 21.5ZM19.6875 19.875C19.3661 19.875 19.0519 19.9703 18.7847 20.1489C18.5175 20.3274 18.3092 20.5812 18.1862 20.8781C18.0632 21.1751 18.031 21.5018 18.0937 21.817C18.1564 22.1322 18.3112 22.4218 18.5385 22.649C18.7657 22.8763 19.0553 23.0311 19.3705 23.0938C19.6857 23.1565 20.0124 23.1243 20.3094 23.0013C20.6063 22.8783 20.8601 22.67 21.0386 22.4028C21.2172 22.1356 21.3125 21.8214 21.3125 21.5C21.3125 21.069 21.1413 20.6557 20.8365 20.351C20.5318 20.0462 20.1185 19.875 19.6875 19.875ZM24.527 7.11367L21.6294 16.5295C21.4776 17.0287 21.169 17.4656 20.7493 17.7755C20.3296 18.0854 19.8212 18.2518 19.2995 18.25H9.53836C9.00919 18.248 8.49491 18.0747 8.07252 17.7559C7.65013 17.4371 7.34236 16.9901 7.19531 16.4818L3.52078 3.625H1.8125C1.59701 3.625 1.39035 3.5394 1.23798 3.38702C1.0856 3.23465 1 3.02799 1 2.8125C1 2.59701 1.0856 2.39035 1.23798 2.23798C1.39035 2.0856 1.59701 2 1.8125 2H3.52078C3.87362 2.00117 4.21659 2.11658 4.49835 2.32896C4.78012 2.54134 4.98553 2.83926 5.08383 3.17812L5.9075 6.0625H23.75C23.8771 6.06245 24.0025 6.09225 24.1161 6.14949C24.2296 6.20672 24.3281 6.28981 24.4037 6.39206C24.4793 6.49431 24.5298 6.61286 24.5512 6.7382C24.5726 6.86353 24.5643 6.99213 24.527 7.11367ZM22.6501 7.6875H6.37164L8.75734 16.0359C8.80588 16.2056 8.90835 16.3549 9.04926 16.4612C9.19018 16.5675 9.36186 16.625 9.53836 16.625H19.2995C19.4734 16.6251 19.6427 16.5694 19.7825 16.4661C19.9224 16.3628 20.0254 16.2174 20.0765 16.0512L22.6501 7.6875Z"
                          fill="black"
                        />
                      </svg>{" "}
                      {cartCounter > 0 ? (
                        <span className="countnumber">{cartCounter}</span>
                      ) : (
                        <div></div>
                      )}
                    </Nav.Link>
                    {isModalOpen && (
                      <ParkingPlanModal
                        isOpen={isModalOpen}
                        onClose={closeModal}
                      />
                    )}
                    {authorid ? (
                      <>

                        <Nav.Link href="/profile" className="afterLogin">
                          <CgProfile className="Usericon" />
                          {authorname}
                        </Nav.Link>
                      </>
                    ) : (
                      <>
                        <Nav.Link
                          onClick={() => {
                            closeMenu();
                            handleShowLoginModal();
                          }}
                          className="beforeLogin"
                        >
                          {" "}
                          {/* Profile Icon - If authorid doesn't exist */}
                          {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="26"
                            height="26"
                            viewBox="0 0 26 26"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M13.4792 1C12.1145 1 10.8056 1.54215 9.84057 2.50718C8.87554 3.47221 8.33339 4.78107 8.33339 6.14583C8.33339 7.51059 8.87554 8.81946 9.84057 9.78449C10.8056 10.7495 12.1145 11.2917 13.4792 11.2917C14.844 11.2917 16.1529 10.7495 17.1179 9.78449C18.0829 8.81946 18.6251 7.51059 18.6251 6.14583C18.6251 4.78107 18.0829 3.47221 17.1179 2.50718C16.1529 1.54215 14.844 1 13.4792 1ZM9.95839 6.14583C9.95839 5.21205 10.3293 4.31651 10.9896 3.65623C11.6499 2.99594 12.5454 2.625 13.4792 2.625C14.413 2.625 15.3085 2.99594 15.9688 3.65623C16.6291 4.31651 17.0001 5.21205 17.0001 6.14583C17.0001 7.07962 16.6291 7.97515 15.9688 8.63544C15.3085 9.29572 14.413 9.66667 13.4792 9.66667C12.5454 9.66667 11.6499 9.29572 10.9896 8.63544C10.3293 7.97515 9.95839 7.07962 9.95839 6.14583ZM13.4792 12.9167C10.9735 12.9167 8.66381 13.4865 6.95323 14.4485C5.26756 15.3975 4.00006 16.834 4.00006 18.6042V18.7147C3.99898 19.9735 3.99789 21.553 5.38348 22.6818C6.06489 23.2365 7.01931 23.6319 8.30848 23.8919C9.59981 24.1541 11.2844 24.2917 13.4792 24.2917C15.6741 24.2917 17.3576 24.1541 18.6511 23.8919C19.9402 23.6319 20.8936 23.2365 21.5761 22.6818C22.9616 21.553 22.9595 19.9735 22.9584 18.7147V18.6042C22.9584 16.834 21.6909 15.3975 20.0063 14.4485C18.2946 13.4865 15.9861 12.9167 13.4792 12.9167ZM5.62506 18.6042C5.62506 17.6823 6.29889 16.6813 7.74948 15.8655C9.17514 15.0638 11.1988 14.5417 13.4803 14.5417C15.7596 14.5417 17.7833 15.0638 19.209 15.8655C20.6606 16.6813 21.3334 17.6823 21.3334 18.6042C21.3334 20.0212 21.2901 20.8185 20.5491 21.4208C20.1482 21.748 19.4766 22.0676 18.3282 22.2994C17.1831 22.5312 15.6177 22.6667 13.4792 22.6667C11.3407 22.6667 9.77423 22.5312 8.63023 22.2994C7.48189 22.0676 6.81023 21.748 6.40939 21.4219C5.66839 20.8185 5.62506 20.0212 5.62506 18.6042Z"
                              fill="black"
                            />
                          </svg> */}
                          <CgProfile className="Usericon" />
                          LogIn{" "}
                        </Nav.Link>
                        <Login
                          show={showLoginModal}
                          handleClose={handleCloseLoginModal}
                          openSignUpModal={handleShowSignUpModal}
                        />
                        <Sign
                          show={showSignUpModal}
                          handleClose={handleCloseSignUpModal}
                          openLoginModal={handleShowLoginModal}
                          onSignUpSuccess={handleSignUpSuccess}
                        />
                        <Modal
                          show={showCallbackModal}
                          onHide={handleCloseCallbackModal}
                          backdrop="static"
                          keyboard={false}
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>Request Callback</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            {/* Render the CallbackRequestForm component */}
                            <CallbackRequestForm
                              onClose={handleCloseCallbackModal}
                            />
                          </Modal.Body>
                        </Modal>
                        <Modal
                          show={showSupportEnquiry}
                          onHide={handleCloseSupportEnquiry}
                          backdrop="static"
                          keyboard={false}
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>Support Enquiry</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            {/* Render the SupportEnquiryForm component */}
                            <SupportEnquiryForm
                              onClose={handleCloseSupportEnquiry}
                            />
                          </Modal.Body>
                        </Modal>

                        <Modal
                          show={showNumberEnquiry}
                          onHide={handleCloseNumberEnquiry}
                          backdrop="static"
                          keyboard={false}
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>Number Enquiry</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            {/* Render the NumberEnquiryForm component */}
                            <NumberEnquiryForm
                              onClose={handleCloseNumberEnquiry}
                            />
                          </Modal.Body>
                        </Modal>
                      </>
                    )}
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </Col>
          </Row>
        </Container>
      </header>
    </>
  );
}

export default Header;
