import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./Testimonials.css";
import { FaStar } from "react-icons/fa";
import { Col, Container, Row } from "react-bootstrap";
import avatar1 from "../Images/testimonial.jpg";
import avatar2 from "../Images/testimonial_images.jpg";
import avatar3 from "../Images/testimonial_images (1).jpg";
import avatar4 from "../Images/testimonial_images_separate_for_testimonials_gir.jpg";
import avatar5 from "../Images/testimonial_images_separate_for_testimonials_gir (1).jpg";
import avatar6 from "../Images/testimonial_boys.jpg";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 4, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
    slidesToSlide: 4, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 767, min: 464 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
  smallerMobile: {
    breakpoint: { max: 463, min: 0 }, 
    items: 1,
    slidesToSlide: 1,
  },
};

const sliderImageData = [
  {
    url: avatar1, 
    comment:
      "I needed a new sim card number urgently and found the perfect solution at NumberKarma. The process was smooth, and I got my number instantly. Great service!",
    author: "Jennifer Taylor",
    rating: 5,
  },
  {
    url: avatar2,
    comment:
      "The process of buying a sim card number was straightforward and user-friendly. Got exactly what I needed.Great choices and excellent service Thanks, NumberKarma!",
    author: "Brian Robinson",
    rating: 5,
  },
  
  {
    url: avatar3,

    comment:
      "I highly recommend NumberKarma for sim card number purchases. The variety is impressive, and the delivery was instant. Five stars!",
    author: "Lily Wilson",
    rating: 5,
  },
  {
    url: avatar4,
    comment:
      "NumberKarma offers a fantastic selection of sim card numbers. I found one that suits my preferences perfectly. Quick and reliable service!",
    author: "Emily Johnson",
    rating: 5,
  },
 
  {
    url: avatar5,
    comment:
      "Exceptional service! The sim card numbers at NumberKarma are unique, and the purchasing process was secure. Will definitely come back for more.",
    author: "Essica Anderson ",
    rating: 5,
  },
  {
    url: avatar6,
    comment:
      "NumberKarma provides a convenient platform for obtaining unique sim card numbers. The options are diverse, and the delivery was prompt. A reliable service!",
    author: "Christopher Wilson",
    rating: 5,
  },
];

const StarRating = ({ rating }) => {
  const stars = Array.from({ length: 5 }, (_, index) => (
    <FaStar key={index} color={index < rating ? "#ffc107" : "#e4e5e9"} />
  ));

  return <div className="star-rating">{stars}</div>;
};

const Slider = () => {
  return (
    <Container fluid className="parent py-5">
      <Row>
      <Col md={12} className="">
          <p className="card-title font-weight-bold mb-4 karmaheading text-center">
            OUR CLIENTS SAYS
          </p>

          <Carousel
            responsive={responsive}
            autoPlay={true}
            swipeable={true}
            draggable={true}
            showDots={true}
            infinite={true}
            partialVisible={false}
            dotListClass="custom-dot-list-style"
          >
            {sliderImageData.map((imageData, index) => (
              <div className="slider" key={index}>
                <div className="slider-1 bg-light p-3 mb-2">
                  <div className="d-flex justify-content-center">
                    <img src={imageData.url} alt="movie" />
                  </div>
                  <p className="comment mt-3">{imageData.comment}</p>
                  <div className="d-flex justify-content-center">
                    <StarRating rating={imageData.rating} />
                  </div>
                  <div className="d-flex justify-content-center text-center">
                    <p className="author m-0">{imageData.author}</p>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
    </Col>
      </Row>
    </Container>
    
  );
};

export default Slider;