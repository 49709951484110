import React from "react";

const Sorting = ({ setSortBy }) => {
  const handleSortChange = (event) => {
    setSortBy(event.target.value);
  };

  return (
    <div className="text-end mt-3 mt-md-0">
      <small>Sort By:</small>
      <select onChange={handleSortChange}>
        <option value="lowToHigh">Low to High</option>
        <option value="highToLow">High to Low</option>
      </select>
    </div>
  );
};

export default Sorting;
