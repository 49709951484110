import { Card, Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";

function Brokerage() {
  return (
    <>
      <Container className="p-2 px-5">
      <Helmet>
        <title> Number brokerage services - Number Buying - NumberKarma</title>
        <meta name="description" content="Get your perfect Phone Number with ease! NumberKarma offers premium brokerage services for buying Numbers - Secure, simple, and fast. Dial into success now!" />
        <meta name="keywords" content="brokerage services, Number Buying Service, Mobile Phone Number, Fancy Phone Number " />
        <link rel="canonical" href="https://numberkarma.com/brokerage" />
      </Helmet>
      <h1 className="text-center mt-5">NumberKarma offers premium brokerage services for buying numbers - Secure, simple, and fast. Dial into success now!</h1>
        <Container className="py-5 mb-5">
        <h2 className="text-center"> Brokerage Services</h2>

        <div className="faq-container mt-4">
            <div className="faq-item">
              <p>
                Our Number buying concierge service solves your Number finding,
                locating and negotiating problems. 
              </p>
              <p>
                Also known as Number buying agents or Experts, we also help with
              </p>
              <Row className="align-items-center">
                <Col md={8}>
                  <h3>NUMBER BUYING SERVICE</h3>
                  <h4>Stress-free Number buying</h4>
                  <p>
                    We're A Number Buying Service You Can Hire To Negotiate With
                    Number Vendors Saving You A Whole Lot Of Time, Stress and
                    Headaches.
                  </p>
                  <p>
                    At NumberKarma, we teach you how we negotiate awesome Number
                    deals without stepping foot inside a Vendors store or
                    website.
                  </p>
                  <p>
                    If you’re too busy, feel anxious, or just want someone to do
                    it for you, our Number buying service saves you time and
                    gives you the peace of mind knowing you’ll never be ripped
                    off. Let our Number buying Experts help.
                  </p>
                </Col>
                <Col md={4}>
                  <img src="/images/saveitem.png"></img>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col md={8}>
                  <p>
                    The traditional Number buying experience drags all day long.
                    Learn how we get our clients awesome deals without spending
                    a minute inside the Vendor.
                  </p>
                </Col>
                <Col md={4}>
                  <img src="/images/saveheadaches.png"></img>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col md={8}>
                  <p>
                    Haggling for a Number deal can cause a lot of anxiety. Not
                    everyone has the personality or knowledge to negotiate
                    effectively. You can learn from us or you can hire an expert
                    Phone Number Expert to haggle for you with our Number buying
                    service!
                  </p>
                  <p>
                    Unlike Number Selling sites that sell your contact info to
                    Vendors and bombard your email and phone, we never share
                    your contact information.
                  </p>
                </Col>
                <Col md={4}>
                  <img src="/images/wework.png"></img>
                </Col>
              </Row>
              <h3>How Does It Work?</h3>
              <p>
                Our Number buying concierge service solves your Number finding,
                locating and negotiating problems. Also known as Number buying
                agents or Experts, we also help with selling and shopping your
                trade in or private party sale. Unlike most Phone Number
                brokers, we don’t accept a dime from Vendors and if we choose to
                work together you pay us a flat fee.
              </p>
              <h4 className="mt-3">Step One </h4>
              Get Started with a free strategy call. Please forward the Name of
              the Venders Website , Store, or Contact Information. Or If you
              Don’t know Availability of Number , then We find them Ourselves.
              <h4 className="mt-3">We’ll discuss the following: </h4>
              <li>Your Number buying goals.</li>
              <li>Define budget.</li>
              <li>Narrowing your Phone Number choice.</li>
              <li>Make a service deposit.</li>
              <h4 className="mt-3">Step Two </h4>
              <li>We go to work for you. </li>
              <li>We contact multiple Vendors for proposals.</li>
              <li>Present you with offers and terms in writing.</li>
              <li>Negotiate pricing of the deal.</li>
              <li>Make counter offers.</li>
              <li>You choose the deal you want to accept.</li>
              <li>
                We coordinate with the Vendor for Transfer of the Phone Number
                and prepare all paperwork.
              </li>
              <h4 className="mt-3">Step Three</h4>
              <li>
                You pick up your Phone Number via Email or we have it delivered
                to your door.
              </li>
           </div>
           </div>
        </Container>
      </Container>
    </>
  );
}

export default Brokerage;
