import React, { useState } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { CgProfile } from "react-icons/cg";
import { GrCart } from "react-icons/gr";
import { HiUserCircle } from "react-icons/hi";
import { CiBellOn } from "react-icons/ci";
import Orders from "./Orders";
import Myprofile from "./Myprofile";
import ManageAddress from "./ManageAddress";
import "./UserProfile.css";
import { IoIosLogOut } from "react-icons/io";
import "bootstrap/dist/css/bootstrap.min.css";
import Notifications from "./Notifications";

function UserProfile() {
  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  // Check if the URL contains a parameter to set the default active tab
  const urlParams = new URLSearchParams(window.location.search);
  const defaultTab = urlParams.get("defaultTab");
  const [activeTab, setActiveTab] = useState(defaultTab || "orders");

  const authorid = sessionStorage.getItem("authorid");
  const authorname = sessionStorage.getItem("authorname");

  const handleLogout = () => {
    if (authorid) {
      sessionStorage.removeItem("authorid");
      sessionStorage.removeItem("authorname");
      window.location.href = "/";
    }
  };

  return (
    <Container fluid>
      <Row className="profile-container">
        {/* Side navigation */}
        <Col md={3} className="UserDataCon">
          <Row className="m-2 ">
            <Col
              sm={6} md={12}
              className="UserData-nav mt-3 py-3 d-flex align-items-center"
            >
              <CgProfile />
              <p className="username m-0">{authorname}</p>
            </Col>
            <Col sm={6} md={12} className="UserData-nav mt-3">
              <GrCart />
              <p
                className="pt-3"
                variant={activeTab === "orders" ? "primary" : "light"}
                onClick={() => setActiveTab("orders")}
              >
                Orders
              </p>
            </Col>

            <Col sm={6} md={12} className="UserData-nav mt-3">
              <HiUserCircle />
              <p
                className="pt-3"
                variant={activeTab === "myprofile" ? "primary" : "light"}
                onClick={() => setActiveTab("myprofile")}
              >
                My Profile
              </p>
            </Col>
            <Col sm={6} md={12} className="UserData-nav mt-3">
                          <CiBellOn />
              <p
                className="pt-3"
                variant={activeTab === "notification" ? "primary" : "light"}
                onClick={() => setActiveTab("notification")}
              >
                Notifications
              </p>
            </Col>

            {/* <Col md={12} className="UserData-nav mt-3 ">
              <BsHouseCheck />
              <p
                className="pt-3"
                variant={activeTab === "manageaddress" ? "primary" : "light"}
                onClick={() => setActiveTab("manageaddress")}
              >
                Manage Address
              </p>
            </Col> */}

            <Col sm={6} md={12} className="UserData-nav mt-3 ">
              <IoIosLogOut />
              <p
                className="pt-3"
                variant={activeTab === "logout" ? "primary" : "light"}
                onClick={() => setActiveTab("logout")}
              >
                Logout
              </p>
            </Col>
          </Row>
        </Col>

        {/* Content area */}
        <Col md={9} className="content">
          {activeTab === "orders" && <Orders />}
          {activeTab === "myprofile" && <Myprofile />}
          {activeTab === "notification" && <Notifications />}
          {activeTab === "manageaddress" && <ManageAddress />}
          {activeTab === "logout" && (
            <div className="d-grid justify-content-center align-content-center">
              <p>Are you sure you want to logout?</p>
              <Button onClick={handleLogout}>Logout</Button>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default UserProfile;
