// import React, { useState, useEffect } from 'react';
// import { Accordion, Nav } from 'react-bootstrap';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import './SideNav.css';

// function SideNav() {
//   const [stateData, setStateData] = useState([]);
//   const [patternData, setPatternData] = useState([]);
//   const [selectedState, setSelectedState] = useState('');
//   const [selectedPattern, setSelectedPattern] = useState('');
//   const navigate = useNavigate();

//   useEffect(() => {
//     // Fetch the list of states from the API
//     axios
//       .get('https://vanityapi.adef.tech/Product/StateList')
//       .then((response) => {
//         setStateData(response.data);
//       })
//       .catch((error) => {
//         console.error('Error fetching state data:', error);
//       });

//     // Fetch the list of patterns from the API
//     axios
//       .get('https://vanityapi.adef.tech/Home/patternDropdown')
//       .then((response) => {
//         setPatternData(response.data);
//       })
//       .catch((error) => {
//         console.error('Error fetching pattern data:', error);
//       });
//   }, []);

//   const handleSelectState = (stateName) => {
//     setSelectedState(stateName);
//     navigate(`/statenumber/${stateName}`);
//   };

//   const handleSelectPattern = (patternId) => {
//     setSelectedPattern(patternId);
//     navigate(`/patternnumbers/${patternId}`);
//   };

//   return (
//     <Nav className="flex-column">
//       <Accordion>
//         {/* State Accordion */}
//         <Accordion.Item eventKey="0">
//           <Accordion.Header>Select State</Accordion.Header>
//           <Accordion.Body className="accordion-body-scrollable">
//             <Nav className="flex-column">
//               {stateData.map((item) => (
//                 <Nav.Link key={item.Id} onClick={() => handleSelectState(item.Statename)}>
//                   {item.Statename}
//                 </Nav.Link>
//               ))}
//             </Nav>
//           </Accordion.Body>
//         </Accordion.Item>

//         {/* Pattern Accordion */}
//         <Accordion.Item eventKey="1">
//           <Accordion.Header>Select Pattern</Accordion.Header>
//           <Accordion.Body className="accordion-body-scrollable">
//             <Nav className="flex-column">
//               {patternData.map((item) => (
//                 <Nav.Link key={item.id} onClick={() => handleSelectPattern(item.pattern1)}>
//                   {item.pattern1}
//                 </Nav.Link>
//               ))}
//             </Nav>
//           </Accordion.Body>
//         </Accordion.Item>
//       </Accordion>
//     </Nav>
//   );
// }

// export default SideNav;

// SideNav.js

import React, { useState, useEffect } from "react";
import { Accordion, Nav } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./SideNav.css";

function SideNav() {
  const [stateData, setStateData] = useState([]);
  const [patternData, setPatternData] = useState([]);
  const [industryData, setIndustryData] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedPattern, setSelectedPattern] = useState("");
  const [selectedIndustry, setSelectedIndustry] = useState("");
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState(null); // State variable to track the active Accordion item

  useEffect(() => {
    // Fetch the list of states from the API
    axios
      .get("https://vanityapi.adef.tech/Product/StateList")
      .then((response) => {
        setStateData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching state data:", error);
      });

    // Fetch the list of patterns from the API
    axios
      .get("https://vanityapi.adef.tech/Home/patternDropdown")
      .then((response) => {
        setPatternData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching pattern data:", error);
      });

    // Fetch the list of industries from the API
    axios
      .get("https://vanityapi.adef.tech/Home/industrytypeDropdown")
      .then((response) => {
        
        setIndustryData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching industry data:", error);
      });

    // Use the pathname to determine which Accordion item to open
    const pathname = window.location.pathname;
    if (pathname.startsWith("/statenumber")) {
      setActiveKey("0");
    } else if (pathname.startsWith("/patternnumbers")) {
      setActiveKey("1");
    } else if (pathname.startsWith("/statenumber")) {
      setActiveKey("2");
    }
  }, []);

  const handleSelectState = (stateName) => {
    setSelectedState(stateName);
    navigate(`/statenumber/${stateName}`);
    setActiveKey('0'); // Set the active Accordion item when a state is selected
  };

  const handleSelectPattern = (patternId) => {
    setSelectedPattern(patternId);
    navigate(`/patternnumbers/${patternId}`);
    setActiveKey('1'); // Set the active Accordion item when a pattern is selected
  };

  const handleSelectIndustry = (industryId) => {
    setSelectedIndustry(industryId);
    navigate(`/industry/${industryId}`);
    setActiveKey('2'); // Set the active Accordion item when an industry is selected
  };

  return (
    <Nav className="flex-column mt-2">
      <Accordion activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
        {/* State Accordion */}
        <Accordion.Item eventKey="0">
          <Accordion.Header>Select State</Accordion.Header>
          <Accordion.Body className="accordion-body-scrollable">
            <Nav className="flex-column">
              <Nav.Link as={Link} to="/filters">All States</Nav.Link>
              {stateData.map((item) => (
                <Nav.Link
                  key={item.Id}
                  onClick={() => handleSelectState(item.Statename)}
                >
                  {item.Statename}
                </Nav.Link>
              ))}
            </Nav>
          </Accordion.Body>
        </Accordion.Item>

        {/* Pattern Accordion */}
        <Accordion.Item eventKey="1">
          <Accordion.Header>Select Pattern</Accordion.Header>
          <Accordion.Body className="accordion-body-scrollable">
            <Nav className="flex-column">
            <Nav.Link as={Link} to="/filters">All Patterns</Nav.Link>
              {patternData.map((item) => (
                <Nav.Link
                  key={item.id}
                  onClick={() => handleSelectPattern(item.pattern1)}
                >
                  {item.pattern1}
                </Nav.Link>
              ))}
            </Nav>
          </Accordion.Body>
        </Accordion.Item>

        {/* Industry Accordion */}
        {/* <Accordion.Item eventKey="2">
          <Accordion.Header>Select Industry</Accordion.Header>
          <Accordion.Body className="accordion-body-scrollable">
            <Nav className="flex-column">
            <Nav.Link as={Link} to="/all">All Industries</Nav.Link>
              {industryData.map((item) => (
                <Nav.Link
                  key={item.id}
                  onClick={() => handleSelectIndustry(item.industryname)}
                >
                  {item.industryname}
                </Nav.Link>
              ))}
            </Nav>
          </Accordion.Body>
        </Accordion.Item> */}
      </Accordion>
    </Nav>
  );
}

export default SideNav;
