// Demandandsupply.js

import React from 'react';
import { Container } from 'react-bootstrap';
import "./Demandandsupply.css";

const Demandandsupply = () => {
    return (
        <Container fluid className="demand-supply-container p-2">
        <Container>
            <section className="demand-supply-section mt-5 mb-4">
                <h1 className="section-title">Demand & Supply</h1>
                <p>
                    In the present times, individuals exhibit a high degree of selectivity regarding what they consume, utilize, wear, or carry.
                    They have become quite conscious regarding the things they want to buy.
                    There is a significant impact of changes in price and availability of Numbers or products on individuals.
                    In this regard, demand and supply are the two forces that are considered to drive the market.
                </p>
                <div className="highlight-text">
                    <p>
                        <strong>Demand:</strong> refers to the quantity of a product or service required by an individual, along with their willingness and ability to pay for it.
                    </p>
                    <p>
                        <strong>Supply:</strong> refers to the overall amount of goods that are available in the market for sale.
                        These two concepts are inversely related to each other because when demand increases, there is a decrease in supply,
                        and when supply increases, there is a decrease in demand. It is important to have equilibrium between demand and supply for the market to function properly.
                    </p>
                </div>
                <p>
                    When we come to number supply, this website has thousands of its own numbers,
                    and we have Business connections with Different Companies and Network Operators, everything is Legal.
                    Our partners have 75 millions of Phone Numbers; we have access to each and every number to buy or sell through our website.
                    So, please let us know your requirement for Phone Numbers through this website just send your Requirement,
                    and we will search for you free, and we will revert to you with Available options.
                </p>
            </section>
        </Container>
        </Container>
    );
};

export default Demandandsupply;
