import { Col, Container, Row, Button, Modal } from "react-bootstrap";
import "./Support.css";
import { useState } from "react";

function Support() {
  const [showWhatsAppPopup, setShowWhatsAppPopup] = useState(false);
  const openWhatsAppPopup = () => {
    setShowWhatsAppPopup(true);
  };

  const closeWhatsAppPopup = () => {
    setShowWhatsAppPopup(false);
  };
  const WhatsAppPopup = ({ onClose }) => {
    return (
      <Modal show={true} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Contact Support</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Please contact support@numberkarma.com for assistance.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  return (
    <>
      <section className="support">
        <Container className="pb-5 pt-4">
          <Row>
            <Col md={12}>
              <p className="px-0 py-2 m-0 sideheading">Support</p>
              <hr />
              <Row className="support">
                <Col md={6} className="px-0">
                  <Row>
                    <Col md={6}>
                      <div className="supportSection">
                        <div>
                          <h4 className="mt-5">Contact us</h4>
                        </div>
                        <div>
                          <p>
                            Looking for support with a personal touch?Feel free
                            to give us a call, send us an email, or start a chat
                            with us for further support. Learn more
                          </p>
                          <Button className="btn" onClick={openWhatsAppPopup}>
                            WhatsApp
                          </Button>

                          {showWhatsAppPopup && (
                            <WhatsAppPopup onClose={closeWhatsAppPopup} />
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <img
                        src="/images/rectangle-93.png"
                        alt=""
                        className="img-fluid"
                      ></img>
                    </Col>
                  </Row>
                </Col>
                <Col md={6} className="secondcolumn">
                  <Row>
                    <Col md={6}>
                      <p className="mt-5">
                        We'll get back to you within 48 hours.
                      </p>
                      <Button
                        block
                        onClick={() =>
                          (window.location.href =
                            "mailto:support@numberkarma.com")
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="18"
                          viewBox="0 0 17 18"
                          fill="none"
                        >
                          <path
                            d="M14.875 3.6875H2.125C1.84321 3.6875 1.57296 3.79944 1.3737 3.9987C1.17444 4.19796 1.0625 4.46821 1.0625 4.75V13.25C1.0625 13.5318 1.17444 13.802 1.3737 14.0013C1.57296 14.2006 1.84321 14.3125 2.125 14.3125H14.875C15.1568 14.3125 15.427 14.2006 15.6263 14.0013C15.8256 13.802 15.9375 13.5318 15.9375 13.25V4.75C15.9375 4.46821 15.8256 4.19796 15.6263 3.9987C15.427 3.79944 15.1568 3.6875 14.875 3.6875ZM13.7062 4.75L8.5 8.35187L3.29375 4.75H13.7062ZM2.125 13.25V5.23344L8.19719 9.43563C8.28612 9.49732 8.39177 9.53038 8.5 9.53038C8.60823 9.53038 8.71388 9.49732 8.80281 9.43563L14.875 5.23344V13.25H2.125Z"
                            fill="black"
                          />
                        </svg>{" "}
                        Email us
                      </Button>
                      <p>9 am - 9 pm, Mon to Sun</p>
                      <Button className="btn" onClick={openWhatsAppPopup}>
                      <svg
                          width="17"
                          height="18"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          data-slot="icon"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                          />
                        </svg>{" "}
                        Call us
                          </Button>

                          {showWhatsAppPopup && (
                            <WhatsAppPopup onClose={closeWhatsAppPopup} />
                          )}
                    </Col>
                    <Col md={6}>
                      <img
                        src="/images/rectangle-94.png"
                        alt=""
                        className="img-fluid
"
                      ></img>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Support;
