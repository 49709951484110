import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faTwitter, faInstagram, faLinkedin, faYoutube, faPinterest } from "@fortawesome/free-brands-svg-icons";
import "./Footer.css";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
    <footer className="footer">
      <Container>
        <Row className="footer-menu text-decoration-none">
        <Col md={3}>
            <h6>Services</h6>
            <ul>
              <li><Link to="/digitalcoldstorage">Digital Cold Storage/Parking</Link></li>
              <li><Link to="/brokerage">Brokerage Service</Link></li>
              <li><Link to="/virtualphonenumbers">Virtual Phone Numbers</Link></li>
              <li><Link to="/customnumbers">Custom Numbers</Link></li>
            </ul>
          </Col>
          <Col md={3}>
            <h6>New Numbers</h6>
            <ul>
              <li><Link to="/local">Local Numbers</Link></li>
              <li><Link to="/VanityNumber">Vanity Numbers</Link></li>
              <li><Link to="/vipnumbers">V.I.P Numbers</Link></li>
              <li><Link to="/tollfreenumbers">Toll Free Numbers</Link></li>
              <li><Link to="/numberBank">Number Search Engine</Link></li>
              <li><Link to="/filters">Numbers On Sale</Link></li>
            </ul>
          </Col>
          <Col md={3}>
          <h6>Privacy</h6>
            <ul>
              <li><Link to="/faqs">FAQ</Link></li>
              <li><Link to="/termsofuse">Terms and conditions</Link></li>
            </ul>
            <h6>Reviews</h6>
            <div className="d-flex align-content-center mb-5">
              <div style={{height:'50px', width:'100%', marginRight:'5px', background:'#fff'}}><img className="img-fluid" src="/images/ab-seal.png" alt="clutchreview"/></div>
              <div 
                style={{height:'50px', width:'100%', marginRight:'5px', background:'#fff', cursor: 'pointer'}} 
                onClick={() => { window.open("https://www.dnb.com/business-directory/company-profiles.number_karma_llc.41fd1df5939d44e4ad0a5cb8a4c56345.html", "_blank") }} 
                className="d-flex align-items-center"
              >
                <img className="img-fluid" src="/images/dun.png" alt="dunreview"/>
              </div>
              <div style={{height:'50px', width:'100%', marginRight:'5px', background:'#fff'}}><img className="img-fluid" src="/images/Trustpilot-Logo.jpg" alt="Trustpilotreview"/></div>
            </div>
          </Col>
          <Col md={3}>
          <h6>Connect</h6>
            <ul>
              {/* <li>Email: admin@numberkarma.com </li> */}
              {/* <li>Phone: +1 (443)-344-3344</li> */}
            <li>General Support: <a onClick={() =>
                          (window.location.href =
                            "mailto:support@numberkarma.com")
                        }>support@numberkarma.com</a></li>
            <li>Sales and Payments: <a onClick={() =>
                          (window.location.href =
                            "mailto:sales@numberkarma.com")
                        }>sales@numberkarma.com</a></li>
            </ul>
            <h6>Social Media</h6>
            <div className="social-icons p-2">
              <a href="https://www.facebook.com/profile.php?id=61554280031570" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebook} size="1x" />
              </a>
              <a href="https://twitter.com/KarmaNumbe83137" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faTwitter} size="1x" />
              </a>
              <a href="https://www.instagram.com/numberkarma/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} size="1x" />
              </a>
              <a href="https://www.linkedin.com/in/numberkarma/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faLinkedin} size="1x" />
              </a>
              <a href="https://www.youtube.com/@NumberKarma" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faYoutube} size="1x" />
              </a>
              <a href="https://www.pinterest.com" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faPinterest} size="1x" />
              </a>
            </div>
          </Col>
        </Row>
        <hr className="text-white my-3"></hr>
        <Row className="copyright">
          <Col md={12}>
            <div>
              <p className="mb-5">&copy; 2024 Number Karma. All rights reserved.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
    </>
  );
}

export default Footer;
