import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import "./Banner.css";

function Banner() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleLoad = () => {
      // Set showButton to true after the page has fully loaded
      setShowButton(true);
    };

    // Attach the load event listener to the window
    window.addEventListener("load", handleLoad);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("load", handleLoad);
    };
  }, []); // Empty dependency array ensures the effect runs only once

  return (
    <>
      <Container fluid>
        <Row>
          <Col
            md={12}
            className="bannerSection p-0 d-flex"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            {showButton && (
              <Container className="bannercontainerbtn px-0">
                <Button
                  className="searchengnebtn verical-align-bottom"
                  onClick={() => {
                    window.location = "/numberBank";
                  }}
                >
                  Start Searching Millions of Numbers
                </Button>
              </Container>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Banner;
