import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Col, Modal, Row } from "react-bootstrap";

function Notifications() {
  const [notifications, setNotifications] = useState([]);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [showModal, setShowModal] = useState(false); // State to control the modal visibility

  // Handler to open the modal
  const handleOpenModal = () => setShowModal(true);

  // Handler to close the modal
  const handleCloseModal = () => setShowModal(false);

  // Handler for when a notification is clicked
  const handleNotificationClick = (notification) => {
    setSelectedNotification(notification);
    handleOpenModal(); // Open the modal when a notification is clicked
  };

  const authorId = sessionStorage.getItem("authorid");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://vanityapi.adef.tech/Customer/GetNotificationsByCustomerId?customerid=${authorId}`
      );
      setNotifications(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  function renderContentWithLineBreaks(content) {
    const words = content.split(' ');
    const truncatedContent = words.slice(0, 15).join(' ');
    return truncatedContent.replace(/\n/g, "<br/>");
  }


  return (
    <>
      {notifications.length > 0 ? (
        notifications.map((notification) => (
          <div className="faq-item" key={notification.id} onClick={() => handleNotificationClick(notification.notification)}>
            <p
              dangerouslySetInnerHTML={{
                __html: renderContentWithLineBreaks(notification.notification)
              }}
            ></p>
            <small>{formatTimestamp(notification.createdon)}</small>
          </div>
        ))
      ) : (
        <div className="faq-item">
          <p className="m-0">No new notifications for you. Enjoy your day!</p>
        </div>
      )}
        <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <p>{selectedNotification}</p>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseModal}>
            Close
          </Button>
          {/* Uncomment and add onClick event to submit button */}
          {/* <Button
            variant='primary'
            onClick={() => {
              PortingtoAPI(authKey, selectedOrderId);
              handleCloseModal();
            }}
          >
            Submit
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}

function formatTimestamp(timestampdate) {
  const timestamp = parseInt(timestampdate.replace(/\/Date\((-?\d+)\)\//, '$1'));
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day} ${hours}:${minutes}:${seconds}`;
}

export default Notifications;
