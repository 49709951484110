import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import logoURL from "../IMG_0169.JPG";
import imageURL from "./images.svg";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Login.css";

function Login({ show, handleClose, openSignUpModal }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();

    const url = "https://vanityapi.adef.tech/Customer/CustLogin";
    const data = new URLSearchParams({
      email,
      password,
    });

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      const responseData = await response.json();
      if (responseData.responseCode === 1) {
        const showSuccessToast = () => {
          toast.success(responseData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        };
        // Call the showSuccessToast function
        showSuccessToast();
        sessionStorage.setItem("authorid", responseData.authorid);
        sessionStorage.setItem("authorname", responseData.customername);
        handleClose();
        window.location.reload();
      } else {
        const showErrorToast = () => {
          toast.error("Login failed. Please check your credentials.", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        };
        showErrorToast();
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg" className="beforeloginmodals">
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid className="custom-container">
            <Row className="login">
              <Col md={12}>
                <div className="mb-3">
                  <Row>
                    <Col md={7}>
                      <img src={imageURL} alt="mage" className="login-image" />
                    </Col>
                    <Col md={5} className="login-form">
                      <div className="form-wrapper d-grid justify-content-center">
                        <img
                          src={logoURL}
                          alt="Logo"
                          className="logo m-auto mt-5"
                        />

                        <Form
                          className="login-pages p-4"
                          onSubmit={handleLogin}
                        >
                          {/* <p>Enter your email id</p> */}
                          <Form.Group controlId="formBasicUsername p-9">
                            <Form.Control
                              type="email"
                              placeholder="Email Address"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </Form.Group>

                          {/* <p>Enter your password</p> */}
                          <Form.Group controlId="formBasicPassword p-9">
                            <Form.Control
                              type="password"
                              placeholder="Password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </Form.Group>

                          <Button
                            className="form-control btn mt-2"
                            type="submit"
                            block
                          >
                            Login
                          </Button>

                          <div className="form-links d-flex justify-content-between">
                            <a
                              href="/forgotpassword"
                              className="small text-muted"
                            >
                              Forgot password?
                            </a>

                            <Link
                              className="register-here"
                              onClick={() => {
                                handleClose();
                                openSignUpModal();
                              }}
                            >
                              Register here
                            </Link>
                          </div>
                        </Form>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
          <ToastContainer />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Login;
