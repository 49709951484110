import React from "react";
import "./Process.css"; // Import your CSS file
import { Col, Container, Image, Row } from "react-bootstrap";

const Process = () => {
  return (
    <Container fluid className="processcontainer p-2">
      <div className="process-container mt-4">
        <div className="process-step">
          <h3>How Do you Own Number?</h3>
          <p>
            Do you want to know more about the process of Purchasing a new phone
            number?
          </p>
        </div>

        <div className="process-step">
          <Row>
            <Col>
            <h3>Step-1 : Selection</h3>

            <p>
              First, should know your requirements and your need for Number.
              Choose your preferred phone number from the list. If you need More
              Numbers, please send your requirement by clicking Custom Number
              ,then we will revert as soon as possible.
            </p>
            </Col>
            <Col md={3}>
                <Image src="/images/Selection.png" className="img-fluid"></Image>
            </Col>
          </Row>
        </div>

        <div className="process-step">
          <Row>
            <Col>
            <h3>Step-2 : Payment</h3>

            <p>
              Move to cart the number you choose and pay for it. We accept
              almost all kinds of payment methods. Do you wish to pay other mode
              of payment then just contact us.
            </p>
            </Col>
            <Col md={3}>
                <Image src="/images/Payment.png" className="img-fluid"></Image>
            </Col>
          </Row>
        </div>

        <div className="process-step">
          <Row>
            <Col>
            <h3>Step-3 : Confirmation and Account Information </h3>

            <p>
              We Authorize Payment and Purchase Confirmation within 24 hours
              after payment.
            </p>
            </Col>
            <Col md={3}>
                <Image src="/images/Confirmation.png" className="img-fluid"></Image>
            </Col>
          </Row>
        </div>

        <div className="process-step">
          <Row>
            <Col>
            <h3>Step 4 : Account Information</h3>

            <p>
              We will send you the new phone number account information within
              24 hours. You need to send it to your Telecom Network Operator and
              activate yourself. This is all Porting Process. You will receive
              Account Information Via Email or What’s App what do you receive?
              <p>a) Account Number b) Pin</p>
            </p>
            </Col>
            <Col md={3}>
                <Image src="/images/Account.png" className="img-fluid"></Image>
            </Col>
          </Row>
        </div>

        <div className="process-step">
          <Row>
            <Col>
            <h3>Step-5 : Porting</h3>

            <p>
              We port out the number into our preferred Telecom Operator. We
              will buy a plan for you to activate your prepaid sim card. The sim
              card will be activated and finally you will receive it, or your
              empty sim card will be activated and you own it permanently.
            </p>
            </Col>
            <Col md={3}>
                <Image src="/images/Porting.png" className="img-fluid"></Image>
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
};

export default Process;
