import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Constants from "./Constants";

const InvoiceComponent = () => {
  const Project_Id = Constants.PROJECTID;

  const navigate = useNavigate();
  const [orderDetails, setOrderDetails] = useState([]);
  const customeridRef = useRef(null);
  const cartidRef = useRef(null);
  const billidRef = useRef(null);
  const billamtRef = useRef(null);
  let Authkey = sessionStorage.getItem('authorid');

  useEffect(() => {
    const url = new URL(window.location.href);

    cartidRef.current = url.searchParams.get('cart');
    billidRef.current = url.searchParams.get('bill');
    billamtRef.current = url.searchParams.get('billamount');
    customeridRef.current = url.searchParams.get('customerid');

    if (customeridRef.current !== null) {
      sessionStorage.setItem('authorid', customeridRef.current);
    }

    Authkey = sessionStorage.getItem('authorid');

    getdatatable();
    getdata();
    getaddress();
  }, []);

  const getdatatable = () => {
    fetch(`https://vanityapi.adef.tech/Bill/Index?authorid=${Authkey}&project_id=${Project_Id}`)
      .then(response => response.json())
      .then(data => {
        // Handle the data as needed
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  const getdata = () => {
    fetch(`https://vanityapi.adef.tech/Bill/Myorderdetails?authorid=${Authkey}&project_id=${Project_Id}&cart_id=${cartidRef.current}`)
      .then(response => response.json())
      .then(data => {
        // Handle the data as needed
        if (Array.isArray(data.responsemessage)) {
          setOrderDetails(data.responsemessage);
        } else {
          console.error('Data is not an array:', data.responsemessage);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  const getaddress = () => {
    fetch(`https://vanityapi.adef.tech/Bill/Billaddress?project_id=${Project_Id}&bill_id=${billidRef.current}`)
      .then(response => response.json())
      .then(data => {
        // Handle the address data
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  const handleOrderClick = () => {
    // Redirect to the InvoiceComponent with parameters
    const url = `/invoice?cart=${cartidRef.current}&bill=${billidRef.current}&billamount=${billamtRef.current}`;
    
    // Use navigate without push
    navigate(url);
  };

  return (
    <div>
      {/* Your JSX content using orderDetails state */}
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Price</th>
            <th>Quantity</th>
            <th>GST Amount</th>
            <th>Total Price</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(orderDetails) ? (
            orderDetails.map((value, index) => (
              <tr key={index}>
                <td>{value.name}</td>
                <td>Rs {value.per_price - value.gst_amount}</td>
                <td>{value.quantity}</td>
                <td>{value.gst_amount}<span id="gsttel"></span></td>
                <td>Rs {value.price}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No order details available</td>
            </tr>
          )}
        </tbody>
      </table>
      
      {/* Button to handle order click */}
      <button onClick={handleOrderClick}>View Order Details</button>
    </div>
  );
};

export default InvoiceComponent;
