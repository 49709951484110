import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Col, Row } from 'react-bootstrap';

const AddressList = ({ Project_Id, Authkey }) => {
  const [addresses, setAddresses] = useState([]);

  useEffect(() => {
    getAddresses();
  }, []); // Fetch addresses on component mount

  const getAddresses = async () => {
    try {
      const response = await axios.get(`https://vanityapi.adef.tech/Addressbook/Index?project_id=${Project_Id}&authorid=${Authkey}`);
      const data = response.data;
      
      if (data && data.id !== "00000000-0000-0000-0000-000000000000") {
        setAddresses(data);
      }
    } catch (error) {
      console.error('Error fetching addresses:', error);
    }
  };

  const renderAddresses = () => {
    if (addresses.length === 0) {
      return (
        <div>
          {/* Message to display when there are no addresses */}
          <p>Add your addresses and enjoy faster checkout</p>
        </div>
      );
    }
    const handleDelete = async (id) => {
      const confirmed = window.confirm('Are you sure you want to delete?');
  
      if (confirmed) {
        try {
          const response = await axios.post(
            `https://vanityapi.adef.tech/Addressbook/Delete?project_id=${Project_Id}&authorid=${Authkey}`,
            { id }
          );
            
          if (response.data.responseCode === 1) {
            // Update the state to remove the deleted address
            setAddresses((prevAddresses) =>
              prevAddresses.filter((address) => address.id !== id)
            );
          }
        } catch (error) {
          console.error('Error deleting address:', error);
        }
      }
    };
  
    const handleDefaultAddress = async (id) => {
      try {
        const response = await axios.post(
          'https://vanityapi.adef.tech/Addressbook/Defaultaddress',
          { Project_id: Project_Id, authorid: Authkey, id }
        );
  
        if (response.data.responseCode === 1) {
          // Handle success, e.g., redirect or show a success message
        }
      } catch (error) {
        console.error('Error setting default address:', error);
      }
    };
  
    return (
      <Row>
        {addresses.map((value) => (
          <Col key={value.id} md={4} className="removediv p-2">
            <div className="address_box addressbox">
              <div>
                <p className={value.isprimary ? "setaddress" : ""}>
                  {value.isprimary ? "Billing Address" : "Set as Billing Address"}
                </p>
              </div>
              <hr />
              <p>
                <input type="radio" id={value.id} name="address" checked={value.isprimary} onChange={() => handleDefaultAddress(value.id)}/>
                <span>&nbsp;&nbsp;</span>
                <span className="Person_name">{value.firstname}</span>
              </p>
              <span className="person_address">{value.address},</span>
              <br />
              <span className="person_address">{value.city},</span>
              <span className="person_address">{value.state},</span>
              <span className="person_address">{value.country}</span>
              <span className="person_address">{value.pincode}</span>
              <p className="person_mobile_no">Mobile: {value.phone}</p>
              <hr />
              <div className='d-flex justify-content-between pt-3'>
              <span id={value.id} className="lbl_edit edit btnedit btn">
                Edit
              </span>
              <span id={value.id} onClick={() => handleDelete(value.id)} className="addressdel Delete mt-2">
                Remove
              </span>
              </div>
              
            </div>
          </Col>
        ))}
      </Row>
    );
  };

  return <div>{renderAddresses()}</div>;
};

export default AddressList;