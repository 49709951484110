
import React, { useState, useEffect } from 'react';
import './Myprofile.css';
import { Button, Col, Modal, Row, ToastContainer } from 'react-bootstrap';
import { toast } from 'react-toastify';
import PayPalComponent from './PayPalComponent';
import PaypalSIM from './PaypalSIM';
import RenewalPaypal from './RenewalPaypal';
import RenewalPaypalThirdParty from './RenewalPaypalThirdParty';
import PaypalSIMThirdParty from './PaypalSIMThirdParty';

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showRenewalModal, setShowRenewalModal] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const authKey = sessionStorage.getItem('authorid');
  const apiURL = 'https://vanityapi.adef.tech/Bill/myorderdetails';
  const [selectedPlan, setSelectedPlan] = useState("");
  const [isThirdPartyRenewal, setIsThirdPartyRenewal] = useState(false);

  const parkingPlans = [
    { id: 3.00, planname: "1 month" },
    { id: 8.00, planname: "3 months" },
    { id: 14.00, planname: "6 months" },
    { id: 24.00, planname: "12 months" },
  ];
  const handlePlanChange = (event) => {
    setSelectedPlan(event.target.value);
  };
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch(`${apiURL}?authorid=${authKey}`);
        const data = await response.json();
        debugger
        if (data.length > 0) {
          // Sort orders by placed_date in descending order (most recent first)
          data.sort((a, b) => {
            const dateA = a.createdon ? new Date(a.createdon) : 0;
            const dateB = b.createdon ? new Date(b.createdon) : 0;
  
            return dateB - dateA;
          });
          setOrders(data);
          setLoading(false); 
        }
        else {
          setOrders([]);
          setLoading(false); // Set loading to false even if no orders are found
        }
      } catch (error) {
        console.error('Error fetching orders:', error);
        setLoading(false); 
      }
    };

    fetchOrders();
  }, [authKey]);
  // Function to handle SIM delivery button click
  const handleSimDelivery = (orderId, isThirdParty) => {
     setSelectedOrderId(orderId);
     setIsThirdPartyRenewal(isThirdParty);
     debugger
    setShowModal(true);
  };
  const handleRenewal = (orderId, isThirdParty) => {
    setSelectedOrderId(orderId);
    debugger
    setIsThirdPartyRenewal(isThirdParty);
    setShowRenewalModal(true);
  };
  // const handleRenewalThirdParty = () => {
  //   debugger
  //   setShowRenewalModal(true);
  // };
  // Function to handle modal close
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleCloseRenewalModal = () => {
    setShowRenewalModal(false);
  };
  
  const handlePayment = async () => {
    // Perform PayPal payment using the selected plan's ID (selectedPlan)
    // You can make an API call or use a PayPal SDK here

    // For demonstration purposes, let's simulate a payment success
    setPaymentStatus("success");
  };
  const selectedPlanDetails =
    selectedPlan &&
    parkingPlans.find((plan) => plan.id === parseInt(selectedPlan));

  return (
    <div>
        {loading ? (
        <p>Loading...</p>
      ) :orders.length > 0 ? (
        <div>
          <h3 className='text-center'>Orders List</h3>
          <div className='table-responsive'>
            <table className='ordersTable'>
              <thead>
                <tr>
                  <th>Purchased Number</th>
                  <th>Account Number</th>
                  <th>Amount</th>
                  <th>PIN</th>
                  <th>BTN</th>
                  <th>Porting</th>
                  <th>Parking Renewal</th>
                </tr>
              </thead>
              <tbody>
                {orders.map((order) => (
                  <tr key={order.cart_id}>
                    <td className='fw-bold'>{order.phonenumber}</td>
                    <td>{order.accountnumber}</td>
                    <td>
                      $
                      {parseFloat(order.amount).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </td>
                    <td>{order.pin}</td>
                    <td>{order.btn}</td>
                    <td>
                      {order.address ? (
                        order.address
                      ) : (                        
                          order.isthirdparty === true ? (
                        <Button onClick={() => handleSimDelivery(order.phonenumber, true)}>SIM Delivery</Button>
                        ):(
                          <Button onClick={() => handleSimDelivery(order.productid)}>SIM Delivery</Button>
                        )
                      )}
                    </td>
                    <td>
                      {
                        order.isthirdparty === true ? (
                          <Button onClick={() => handleRenewal(order.phonenumber, true)}>Parking Renewal</Button>
                        ):(
                          <Button onClick={() => handleRenewal(order.productid)}>Parking Renewal</Button>
                        )
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div>
          <p>No orders found!</p>
        </div>
      )}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>SIM Delivery</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <p>Certainly! Before finalizing the SIM delivery, there is a nominal delivery charge of $49.00. Are you ready to proceed with the SIM delivery and accept the associated delivery fee?</p>
            </Col>
            <Col md={3}></Col>
            <Col md={6}>
            {isThirdPartyRenewal ? (
              <PaypalSIMThirdParty amount={49} orderId={selectedOrderId} />
            ):(
              <PaypalSIM amount={49} orderId={selectedOrderId} />
            )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseModal}>
            Cancel
          </Button>
          {/* <Button
            variant='primary'
            onClick={() => {
              PortingtoAPI(authKey, selectedOrderId);
              handleCloseModal();
            }}
          >
            Submit
          </Button> */}
        </Modal.Footer>
      </Modal>
            {/* Renewal Modal */}
            <Modal show={showRenewalModal} onHide={handleCloseRenewalModal}>
        <Modal.Header closeButton>
          <Modal.Title>Parking Renewal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handlePayment} className='text-center mt-5'>
        <label>
          Parking Plan:
          <select value={selectedPlan} onChange={handlePlanChange}>
            <option value="" disabled>
              Select a plan
            </option>
            {parkingPlans.map((plan) => (
              <option key={plan.id} value={plan.id}>
                {plan.planname}
              </option>
            ))}
          </select>
        </label>
        <br />
        {selectedPlanDetails && (
          <div>
            <p className='text-center mt-3'>
            You choose a <b>{selectedPlanDetails.planname}</b> plan with a <b>${selectedPlan}</b> amount. </p>
            <p>Proceed with PayPal payments.</p>
          </div>
        )}
        <br />
        {isThirdPartyRenewal ? (
        // Render components for third-party renewal logic
        <RenewalPaypalThirdParty parkingamount={selectedPlan} orderId={selectedOrderId} parkingplan={selectedPlanDetails.planname} />
      ) : (
        // Render components for regular renewal logic
        <RenewalPaypal amount={selectedPlan} orderId={selectedOrderId} parkingplan={selectedPlanDetails.planname} />
      )}
      </form>

        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseRenewalModal}>
            Cancel
          </Button>
          {/* Add your renewal modal submit button here */}
        </Modal.Footer>
      </Modal>

      <ToastContainer></ToastContainer>
    </div>
  );
};

export default Orders;
