import React, { useState, useEffect } from "react";
import { Container, Col, Row, Pagination } from "react-bootstrap";
import "./Vippremiumnumbers.css";
import { FaMapMarkerAlt } from "react-icons/fa";
import CartButton from "./CartButton";
import axios from "axios";

function Vippremiumnumbers() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); // Add this line
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(20);

  useEffect(() => {

    axios
      .get("https://vanityapi.adef.tech/Home/OursaleAndVIPnumbers")
      .then((response) => {
        setProducts(response.data.vip);
        debugger;
        setLoading(false);
        if (response.data.vip.length === 0) {
          setError("No VIP numbers available.");
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
        setError("Error fetching data. Please try again."); // Set the error state
        setLoading(false);
      });
  }, []);

  const renderProducts = () => {
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = products.slice(
      indexOfFirstProduct,
      indexOfLastProduct
    );

    if (loading) {
      return <p>Loading...</p>;
    }
    if (error) {
      return <p>{error}</p>;
    }

    // Add a check to ensure that products is defined and is an array
    if (!Array.isArray(currentProducts)) {
      console.error("Invalid products data:", currentProducts);
      return <p>Error: Invalid data format</p>;
    }
    return currentProducts.map((product) => (
      <Col md={6} key={product.id} className="p-0">
        <div className="box">
          <Row>
            <Col md={6}>
              <p className="box-number text-start ">{product.name}</p>
            </Col>
            <Col md={6}>
              <p className="box-price text-end m-0">
                {product.discount == 0 ? (
                  <span>
                    $
                    {parseFloat(product.rate).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                ) : (
                  <>
                    <strike>
                      $
                      {parseFloat(product.rate).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </strike>
                  </>
                )}
                <span className="box-discount text-end">
                  ({product.discount}% Off)
                </span>
              </p>
              <p className="box-price text-end m-0">
                {product.discount == 0 ? (
                  <></>
                ) : (
                  <>
                    $
                    {parseFloat(product.price).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </>
                )}
                <span className="box-discount text-end">
                  Save $
                  {(
                    parseFloat(product.rate) - parseFloat(product.price)
                  ).toFixed(2)}
                </span>
              </p>
            </Col>
            <Col md={6}>
              <button className="location-button mb-3">
                <FaMapMarkerAlt /> {product.state}
              </button>
              <p className="box-patterns m-0">Pattern: {product.pattern}</p>
              <p className="activatetext">Activate in 2-7 days</p>
            </Col>
            <Col md={6} className="mt-auto">
              {product.stock > 0 && (
                <>
                  <p className="statustext">{product.status}</p>
                  <CartButton
                    productId={product.id}
                    className="buybutton"
                    discountprice={product.price}
                  ></CartButton>
                  {product.rate > 5000 && (
                    <>
                      <p className="offertext">You can Make an Offer</p>
                    </>
                  )}
                </>
              )}

              {product.stock === 0 && (
                <p className="sold-out-text text-end">Sold Out</p>
              )}
            </Col>
            <Col md={6}></Col>
          </Row>
        </div>
      </Col>
    ));
  };
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <Container>
        <Row className="mt-5">
          <Col md={12}>
            <p className="px-0 py-2 m-0 sideheading"> Vip Premium Numbers</p>
            <hr></hr>
          </Col>
        </Row>
      </Container>
      <Container className="pb-5">
        <Row className="mt-3">{renderProducts()}</Row>
        <Pagination>
          {Array.from({ length: Math.ceil(products.length / productsPerPage) }, (_, i) => (
            <Pagination.Item key={i} active={i + 1 === currentPage} onClick={() => paginate(i + 1)}>
              {i + 1}
            </Pagination.Item>
          ))}
        </Pagination>
      </Container>
    </>
  );
}

export default Vippremiumnumbers;
