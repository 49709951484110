import { useEffect, useState } from "react";
import "./Shop.css";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";

function Shop() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Load the external script
    const script = document.createElement("script");
    

    script.src =
      "https://embed-widget.s3-us-west-2.amazonaws.com/p/assets/number_search.js";
    script.async = true;
    script.id = "searchSnippet";

    script.onload = () => {
      // The script has loaded, and you can use it here
      //   const widgetToken = script.getAttribute('widgetToken');
      // You can now interact with the script or the widget here
      setLoading(false); // Set loading to false once the script is loaded
    };

    // Append the script to the document
    document.body.appendChild(script);

    return () => {
      // Cleanup if needed
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <section className="shopPage">
        <Container className="py-5 ">
        <Helmet>
        <title> Number Bank, Custom Telephone Number | NumberKarma</title>
        <meta name="description" content=" Get your memorable custom Phone Number Bank with NumberKarma! Elevate your brand with a unique number that makes a lasting impression. Dial up success today!" />
        <meta name="keywords" content="numberBank, custom telephone number, custom telephone numbers, customize phone number, get a custom phone number" />
        <link rel="canonical" href="https://numberkarma.com/numberBank" />
      </Helmet>
      <h1 className="text-center mb-5">Memorable custom phone number bank with NumberKarma</h1>
        <div className="sticky-image">
              <img
                src="/images/hide.png"
                alt=""
                width={200}
                className="img-fluid sticky_image"
              ></img>
            </div>
          <h2 className="text-center ">Your Perfect Number Awaits!!</h2>
              <h5 className="text-center mb-5">
                Explore a Wealth of Options with State, City, Area Code, and
                Price Range Filters
              </h5>
              {loading && <p>Loading...</p>} {/* Display loading message or spinner */}

          <div id="search"></div>
        </Container>
      </section>
    </>
  );
}

export default Shop;