import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./State.css";
import { Link } from "react-router-dom";

function State() {
  const [stateData, setStateData] = useState([]);

  useEffect(() => {
    fetch("https://vanityapi.adef.tech/Product/StateList")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setStateData(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  

  const stateList = stateData.map((item) => (
    <Col md={3} key={item.Id}>
      <Link className="state-info text-decoration-none text-center" to={`/statenumber/${item.Statename}`}>
        <div className="state-card">
          <div className="image-container">
            <img src={item.image} alt={item.Statename} />
          </div>
          <div className="mt-2">
            <p className="state-name">{item.Statename}</p>
            {/* <p className="area-code">{item.Areacode}</p> */}
          </div>
        </div>
      </Link>
    </Col>
  ));

  return (
    <Container fluid className="statesPage">
      <Row className="mt-5">
        <Col md={4}></Col>
        <Col md={3} className="text-center">
          <h2>State List</h2>
        </Col>
        <Col md={4}></Col>
      </Row>
      <Container>
        <Row className="mt-3">
          {stateList}
        </Row>
      </Container>
    </Container>
  );
}

export default State;
