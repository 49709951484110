import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Whynumberkarma.css";

const iconLinks = [
  {
    iconUrl: '/images/rectangle-108(1).png',
    title: "Increase Brand Awareness",
    staticText: "Custom phone numbers are an affordable, long-term asset that extends the reach of your brand.",
  },
  {
    iconUrl: '/images/rectangle-108.png',
    title: "Improve Advertising Response Rate",
    staticText: "A vanity phone number is like adding gas into the tank of your marketing outreach engine.",
  },
  {
    iconUrl: '/images/rectangle-108(3).png',
    title: "Gain Competitive Advantage",
    staticText: "Your customers are constantly seeing ads for your competitors, stand out, appear more established and make sure it's your number they call.",
  },
];

const Whynumberkarma = () => {
  return (
    <section className="whysection" id="whynumberkarma">
    <Container>
      <Row className="mt-3">
        <p className="sideheading">Why Number Karma</p>
        <hr></hr>
      <p className="card-title font-weight-bold mt-4 karmaheading">A USER-DRIVEN PLATFORM THAT ASPIRES TO 
               CREATE A SUPERIOR VANITY NUMBERS</p>
        
      </Row>
    </Container>
    <Container>
      <Row>
      {iconLinks.map((link, index) => (
          <Col md={4} key={index}>
            <div className="icon-container">
                <br></br>
              <img src={link.iconUrl} alt="Icon" className="small-icon-image" />
            </div>
            <h5 className="card-title my-4">{link.title}</h5>
            <p>{link.staticText}</p>
          </Col>
        ))}
      </Row>
    </Container>
    </section>
  );
};

export default Whynumberkarma;
