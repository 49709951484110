import React, { useEffect, useState } from "react";
import axios from "axios";
import { Col, Collapse, Container, Row } from "react-bootstrap";
import Constants from "./Constants";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const MakeAnOffer = ({ productId, onClose, onSubmitSuccess }) => {
  const Project_Id = Constants.PROJECTID;
  const [offerAmount, setOfferAmount] = useState("");
  const [username, setUsername] = useState("");
  const [contactPhoneNumber, setContactPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [state, setState] = useState(""); // Add this line

  const [usaStates, setUsaStates] = useState([]);
  const [canadaStates, setCanadaStates] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [country, setCountry] = useState("");
  const [productName, setProductName] = useState("");
  const [ProductPrice, setProductPrice] = useState("");
  const [cartId, setCartId] = useState("");
  const [stateData, setStateData] = useState([]);
  useEffect(() => {
    fetch("https://vanityapi.adef.tech/Product/StateList")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setStateData(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  const handleStateChange = (e) => {
    const value = e.target.value;
    setState(value); // Update to use setState
  };

  const handleCountryChange = async (e) => {
    const value = e.target.value;
    setCountry(value);
    setState(""); // Reset state when changing country

    // Fetch states based on the selected country
    try {
      let response;
      let data;

      if (value === "U.S.A") {
        response = await fetch("https://vanityapi.adef.tech/Product/StateList");
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        data = await response.json();
        setUsaStates(data);
      } else if (value === "Canada") {
        response = await fetch("https://vanityapi.adef.tech/Home/canadastates");
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        data = await response.json();
        setCanadaStates(data);
      }
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };


  const sortedStateData = stateData.slice().sort((a, b) => {
    // Assuming Statename is a string, you may need to modify the comparison based on your data
    return a.Statename.localeCompare(b.Statename);
  });
  const handleOfferSubmit = async () => {
    try {
      if (!offerAmount || isNaN(offerAmount) || !username || !email) {
        const showErrorToast = () => {
          toast.error('Please fill in all required fields.', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        };
        showErrorToast();
        return;
      }
      if (!productId) {
        console.error("productId is not defined or invalid.");
        return;
      }
      // // Fetch product name and price simultaneously
      // const [productNameResponse, productPriceResponse] = await Promise.all([
      //   fetch(`https://vanityapi.adef.tech/Home/Productview?project_id=${Project_Id}&id=${productId}`)
      //   .then(response => {
      //     if (!response.ok) {
      //       throw new Error(`HTTP error! Status: ${response.status}`);
      //     }
      //     return response.json();
      //   }),
      // ]);
      
       setProductName(productId);
      // setProductPrice(productNameResponse.data.price);
  
      // Proceed with the rest of the code
      const cartId = sessionStorage.getItem("cartId");
      setCartId(cartId);
  
      const requestData = {
        username,
        contactPhoneNumber,
        email,
        cartId,
        state,
        country,
        offerAmount: parseFloat(offerAmount),
        productName: productId,
      };
  
      const data = new URLSearchParams(requestData);
  
      const response = await fetch("https://vanityapi.adef.tech/Review/MakeAnOffer", {
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
  
      const responseData = await response.json();
      if (responseData.responseCode === 0) {
        const showSuccessToast = () => {
          toast.success(responseData.responsemessage, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        };
        showSuccessToast();
        onClose();
        // Call the onSubmitSuccess function passed from MakeAnOfferButton
        onSubmitSuccess();
      }
      else{
        const showSuccessToast = () => {
          toast.success(responseData.responsemessage, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        };
        showSuccessToast();
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle error
    }
  };
  
  return (
    <>
    <Container>
      <div className="offerForm px-4">
      <form>
        <div className="d-flex justify-content-between">
            <div md={4}>
              <label htmlFor="username">Your Name:</label>
            </div>
            <div md={8}>
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
          </div>
          <Row className="align-items-center">
            <Col md={5}>
              <label htmlFor="contactPhoneNumber">Contact Mobile:</label>
            </Col>
            <Col md={7}>
            <PhoneInput
              defaultCountry={'us'} // Default country
              enableAreaCodes={true}
              disableCountryCode={true} // Hide country code
              value={contactPhoneNumber}
              onChange={(value) => setContactPhoneNumber(value)}
            />
            </Col>
          </Row>
          <div className="d-flex justify-content-between">
            <div md={4}>
              <label htmlFor="email">Email:</label>
            </div>
            <div md={8}>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <Row className="d-flex justify-content-between">
            <Col md={5}>
              <label htmlFor="country">Country:</label>
            </Col>
            <Col md={7}>
            <select
                  id="country"
                  // value={formData.country}
                  onChange={handleCountryChange}
                  style={{ width: '100%' }}
                >
                  <option value="" disabled>
                    Select Country
                  </option>
                  <option value="U.S.A">U.S.A</option>
                  <option value="Canada">Canada</option>
                </select>
            </Col>
          </Row>
          <Row className="d-flex justify-content-between">
            <Col md={5}>
              <label htmlFor="state">State:</label>
            </Col>
            <Col md={7}>
            <select
                  id="state"
                  // value={formData.state}
                  onChange={handleStateChange}
                  style={{ width: '100%' }}
                >
                  <option value="">Select State</option>
                  {country === "U.S.A" &&
                   usaStates
                   .slice()
                   .sort((a, b) => a.Statename.localeCompare(b.Statename))
                   .map((state) => (
                     <option key={state.StateId} value={state.Statename}>
                       {state.Statename}
                     </option>
                    ))}
                  {country === "Canada" &&
                    canadaStates
                    .slice()
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((state) => (
                      <option key={state.id} value={state.name}>
                        {state.name}
                      </option>
                    ))}
                </select>
            </Col>
          </Row>
          
          <div className="d-flex justify-content-between">
            <div md={4}>
              <label htmlFor="offerAmount">Offer Amount:</label>
            </div>
            <div md={8}>
              <input
                type="text"
                id="offerAmount"
                value={offerAmount}
                onChange={(e) => setOfferAmount(e.target.value)}
              />
            </div>
          </div>
          <button type="button" className="btn my-5" onClick={handleOfferSubmit}>
            Submit Offer
          </button>
        </form>

        <ToastContainer />
      </div>
      </Container>
    </>
  );
};

export default MakeAnOffer;
