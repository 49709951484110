import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap"; // Assuming you are using react-bootstrap
import MakeAnOffer from "./MakeAnOffer";

const MakeAnOfferButton = ({ productId, isOpen, onRequestClose  }) => {
  const [showModal, setShowModal] = useState(false);
  const [offerSubmitted, setOfferSubmitted] = useState(false);

  const handleShow = () => {
    setShowModal(true);
  }
  const handleClose = () => {
    setShowModal(false);
  };
   // Function to be called after a successful offer submission
   const handleOfferSubmitSuccess = () => {
    // Set offerSubmitted to true to hide the "Make an Offer" button
    setOfferSubmitted(true);
    // Close the modal
    handleClose();
  };
  return (
    <>
     {!offerSubmitted && (
        <div>
          <Button variant="primary" className="offerbtn px-0 pb-0 mr-auto buybutton w-100" onClick={handleShow}>
            Make an Offer
          </Button>
        </div>
      )}
      
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Make an Offer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MakeAnOffer productId={productId} onClose={handleClose} onSubmitSuccess={handleOfferSubmitSuccess}/>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MakeAnOfferButton;
