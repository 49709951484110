import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import Login from "./Login";
import Sign from "./Sign";

function OTP() {
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSignUpModal, setShowSignUpModal] = useState(false);

  const handleShowLoginModal = () => setShowLoginModal(true);
  const handleCloseLoginModal = () => setShowLoginModal(false);

  const handleShowSignUpModal = () => setShowSignUpModal(true);
  const handleCloseSignUpModal = () => setShowSignUpModal(false);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const emailParam = searchParams.get("email");
    setEmail(emailParam || "");

  }, [location.search]);
  const registrationOtp = async () => {
    const url = "https://vanityapi.adef.tech/Customer/CompleteRegistration";
    var enteredOTP = otp;
    const data = new URLSearchParams({
      enteredOTP,
    });

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      
      const responseData = await response.json();
      if (responseData.responseCode === 1) {
        const showSuccessToast = () => {
          toast.success(responseData.responsemessage, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            onClose: () => {
                setShowLoginModal(true); // Set state to show the login modal
            },
          });
        };
        showSuccessToast();
      } else {
        const showErrorToast = () => {
          toast.error(responseData.responsemessage, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        };
        showErrorToast();
      }
    } catch (error) {
      console.error("Additional AJAX call error:", error);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    
    const url = "https://vanityapi.adef.tech/Customer/VerifyOTPForForgotPassword";
    const data = new URLSearchParams({
      email,
      otp,
    });

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      
      const responseData = await response.json();
      if (responseData.responseCode === 1) {
        const showSuccessToast = () => {
          toast.success(responseData.responsemessage, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            onClose: () => {
              window.location = "/resetpassword?email=" + email;
            },
          });
        };
        showSuccessToast();
      } else {
        const showErrorToast = () => {
          toast.error(responseData.responsemessage, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        };
        showErrorToast();
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };
  const conditionalSubmit = (event) => {
    event.preventDefault();
    if (email) {
      handleSubmit(event);
    } else {
      registrationOtp();
    }
  };
  return (
    <>
      <Container className="py-5">
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <h3>Enter your OTP received via email</h3>
            <div>
              <form id="frmotp" className="mb-3" onSubmit={conditionalSubmit}>
                <div className="form-floating form-floating-outline mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="txtotp"
                    name="OTP"
                    placeholder="Enter OTP"
                    autoFocus
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                  />
                </div>
                <button type="submit" className="btn btn-primary d-grid w-100">
                  Submit
                </button>
              </form>
            </div>
            <ToastContainer />
          </Col>
        </Row>
      </Container>
      {showLoginModal && (
        <>
         <Login show={showLoginModal} handleClose={handleCloseLoginModal} openSignUpModal={handleShowSignUpModal} />
         <Sign show={showSignUpModal} handleClose={handleCloseSignUpModal} openLoginModal={handleShowLoginModal} />
        </>
      )}
    </>
  );
}

export default OTP;
