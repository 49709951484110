import { Container } from "react-bootstrap";

function TermsOfUse() {
    return ( 
        <>
            <Container className="py-5">
            <p align="center"><strong>Terms and Conditions</strong></p>

<p><strong>Agreement between User and www.numberkarma.com</strong></p>
<p>Welcome to www.numberkarma.com. The www.numberkarma.com website (the &quot;Site&quot;) is comprised of various web pages operated by Number Karma, LLC (&quot;NumberKarma&quot;). www.numberkarma.com is offered to you conditioned on your acceptance without modification of the terms, conditions, and notices contained herein (the &quot;Terms&quot;). Your use of www.numberkarma.com constitutes your agreement to all such Terms. Please read these terms carefully, and keep a copy of them for your reference.</p>

<p>www.numberkarma.com is an E-Commerce Site.</p>

<p>Selling Phone Numbers and other software services</p>

<p><strong>Electronic Communications</strong></p>
<p>Visiting www.numberkarma.com or sending emails to NumberKarma constitutes electronic communications. You consent to receive electronic communications and you agree that all agreements, notices, disclosures and other communications that we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communications be in writing.</p>

<p><strong>Your Account</strong></p>
<p>If you use this site, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account or password. You may not assign or otherwise transfer your account to any other person or entity. You acknowledge that NumberKarma is not responsible for third party access to your account that results from theft or misappropriation of your account. NumberKarma and its associates reserve the right to refuse or cancel service, terminate accounts, or remove or edit content in our sole discretion.</p>

<p><strong>Children Under Thirteen</strong></p>
<p>NumberKarma does not knowingly collect, either online or offline, personal information from persons under the age of thirteen. If you are under 18, you may use www.numberkarma.com only with permission of a parent or guardian.</p>

<p><strong>Cancellation/Refund Policy</strong></p>
<p>Due to the nature of NumberKarma Business, returns, refunds or replacements are not always possible.</p>

<p><strong>Order cancellation:</strong> All orders may &nbsp;be cancelled until they are Activated. If your order has been paid and you need to change or cancel it, you must contact us before you receive porting information. To receive refund , contact us before 12 hours of sale and will not be refunded &nbsp;incase once you receive your Porting information. Once the activating process has started, it can no longer be cancelled. Restocking fee will be charged to get refund &nbsp;during most &nbsp;of cancellations , even you respond with in 12 hours after purchase.&nbsp;</p>

<p><strong>Restocking Fee:</strong> The restocking fee is a customer&rsquo;s additional charge when returning a Number. It covers the cost of returning the product to resellable condition and can range from<strong>&nbsp;25-50%</strong> of the original purchase price, depending on the our Business Partner Refund policy.</p>

<p><strong>Refunds:</strong> We offer a 100% money back guarantee on all phone numbers incase bellow mentioned.</p>
<ul style={{listStyle: "disc", marginLeft: "20px"}}>
    <li>
        Numbers are not working, not activated or unable to Porting out.
    </li>
    <li>
        Order may be cancelled due to Number Unavailability.
    </li>
    <li>
        Numbers may show up available even sold to someone due to Technical Glitches.
    </li>
</ul>
<p>Just let us know and we will provide a replacement or refund as preferred.</p>
<p>If you are unsatisfied with your order &ndash; please contact our support and we will do our best to help you out.</p>
<p><strong>Our Privacy Policy:&nbsp;</strong></p>
<p>At www.numberkarma.com , we ask you :</p>
<ul style={{listStyle: "disc", marginLeft: "20px"}}>
    <li>
        Your Name and Lastname.
    </li>
    <li>
        Your Email Address.
    </li>
    <li>
        Your Physical Address. 
    </li>
    <li>
        Your phone number. 
    </li>
    <li>
        The data about the browser and device you use to view the store.
    </li>
    <li>
        The way you navigate the store.
    </li>
    <li>
        We gather your contact details because they are necessary to accept and process your orders, and to make sure you&rsquo;ve got your packages.
    </li>
</ul>
<p>We gather the details of your technical equipment and on-site behavior in order to make our store more user-friendly, and to personalize our store services for you (for example, to automatically switch the store to the mobile version.)</p>
<p>Our store works with other companies that help us provide the best service for you, and these third parties also use some of the personal details you&rsquo;re leaving. We limit the data they can access to only what is necessary for them to perform their obligations.</p>
<ul style={{listStyle: "disc", marginLeft: "20px"}}>
    <li>
        Payment services use your credit card number, your name and surname to verify and process your payments for our products.
    </li>
    <li>
        Our manufacturers and stock keepers use the data of your order contents to assemble the necessary package for you.
    </li>
    <li>
        Postal services use your first name, last name, and physical address to arrange the product delivery for you.
    </li>
    <li>
        Mass mailing services use your email address to send you emails (if you have subscribed for them)
    </li>
</ul>
<p>If you keep browsing our webstore after reading this Privacy Policy, you give us the consent to use your personal details for the purposes explained above.</p>
<p>If you don&rsquo;t agree to these terms, please leave the website.</p>
<p>You can email us at admin@numberkarma.com and ask:</p>
<ul style={{listStyle: "disc", marginLeft: "20px"}}>
    <li>
        To receive the copy of your personal details we have collected.
    </li>
    <li>
        To delete your personal details from our system.
    </li>
    <li>
        To withdraw your consent (if you previously agreed to provide us with the data, but then changed your mind)
    </li>
</ul>
<p>We are doing our best to guarantee the security of your personal details while keeping and using them.</p>
<p>Thank you for your cooperation!</p>

<p><strong>Links to Third Party Sites/Third Party Services</strong></p>
<p>www.numberkarma.com may contain links to other websites (&quot;Linked Sites&quot;). The Linked Sites are not under the control of NumberKarma and NumberKarma is not responsible for the contents of any Linked Site, including without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. NumberKarma is providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by NumberKarma of the site or any association with its operators.</p>

<p>Certain services made available via www.numberkarma.com are delivered by third party sites and organizations. By using any product, service or functionality originating from the www.numberkarma.com domain, you hereby acknowledge and consent that NumberKarma may share such information and data with any third party with whom NumberKarma has a contractual relationship to provide the requested product, service or functionality on behalf of www.numberkarma.com users and customers.</p>

<p><strong>No Unlawful or Prohibited Use/Intellectual Property&nbsp;</strong></p>
<p>You are granted a non-exclusive, non-transferable, revocable license to access and use www.numberkarma.com strictly in accordance with these terms of use. As a condition of your use of the Site, you warrant to NumberKarma that you will not use the Site for any purpose that is unlawful or prohibited by these Terms. You may not use the Site in any manner which could damage, disable, overburden, or impair the Site or interfere with any other party&apos;s use and enjoyment of the Site. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Site.</p>

<p>All content included as part of the Service, such as text, graphics, logos, images, as well as the compilation thereof, and any software used on the Site, is the property of NumberKarma or its suppliers and protected by copyright and other laws that protect intellectual property and proprietary rights. You agree to observe and abide by all copyright and other proprietary notices, legends or other restrictions contained in any such content and will not make any changes thereto.</p>

<p>You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create derivative works, or in any way exploit any of the content, in whole or in part, found on the Site. NumberKarma content is not for resale. Your use of the Site does not entitle you to make any unauthorized use of any protected content, and in particular you will not delete or alter any proprietary rights or attribution notices in any content. You will use protected content solely for your personal use, and will make no other use of the content without the express written permission of NumberKarma and the copyright owner. You agree that you do not acquire any ownership rights in any protected content. We do not grant you any licenses, express or implied, to the intellectual property of NumberKarma or our licensors except as expressly authorized by these Terms.</p>

<p><strong>Use of Communication Services</strong></p>
<p>The Site may contain bulletin board services, chat areas, news groups, forums, communities, personal web pages, calendars, and/or other message or communication facilities designed to enable you to communicate with the public at large or with a group (collectively, &quot;Communication Services&quot;). You agree to use the Communication Services only to post, send and receive messages and material that are proper and related to the particular Communication Service.</p>

<p>By way of example, and not as a limitation, you agree that when using a Communication Service, you will not: defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as rights of privacy and publicity) of others; publish, post, upload, distribute or disseminate any inappropriate, profane, defamatory, infringing, obscene, indecent or unlawful topic, name, material or information; upload files that contain software or other material protected by intellectual property laws (or by rights of privacy of publicity) unless you own or control the rights thereto or have received all necessary consents; upload files that contain viruses, corrupted files, or any other similar software or programs that may damage the operation of another&apos;s computer; advertise or offer to sell or buy any goods or services for any business purpose, unless such Communication Service specifically allows such messages; conduct or forward surveys, contests, pyramid schemes or chain letters; download any file posted by another user of a Communication Service that you know, or reasonably should know, cannot be legally distributed in such manner; falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin or source of software or other material contained in a file that is uploaded; restrict or inhibit any other user from using and enjoying the Communication Services; violate any code of conduct or other guidelines which may be applicable for any particular Communication Service; harvest or otherwise collect information about others, including e-mail addresses, without their consent; violate any applicable laws or regulations.</p>

<p>NumberKarma has no obligation to monitor the Communication Services. However, NumberKarma reserves the right to review materials posted to a Communication Service and to remove any materials in its sole discretion. NumberKarma reserves the right to terminate your access to any or all of the Communication Services at any time without notice for any reason whatsoever.</p>

<p>NumberKarma reserves the right at all times to disclose any information as necessary to satisfy any applicable law, regulation, legal process or governmental request, or to edit, refuse to post or to remove any information or materials, in whole or in part, in NumberKarma&apos;s sole discretion.</p>

<p>Always use caution when giving out any personally identifying information about yourself or your children in any Communication Service. NumberKarma does not control or endorse the content, messages or information found in any Communication Service and, therefore, NumberKarma specifically disclaims any liability with regard to the Communication Services and any actions resulting from your participation in any Communication Service. Managers and hosts are not authorized NumberKarma spokespersons, and their views do not necessarily reflect those of NumberKarma.</p>

<p>Materials uploaded to a Communication Service may be subject to posted limitations on usage, reproduction and/or dissemination. You are responsible for adhering to such limitations if you upload the materials.</p>

<p><strong>Materials Provided to www.numberkarma.com or Posted on Any NumberKarma Web Page</strong></p>
<p>NumberKarma does not claim ownership of the materials you provide to www.numberkarma.com (including feedback and suggestions) or post, upload, input or submit to any NumberKarma Site or our associated services (collectively &quot;Submissions&quot;). However, by posting, uploading, inputting, providing or submitting your Submission you are granting NumberKarma, our affiliated companies and necessary sublicensees permission to use your Submission in connection with the operation of their Internet businesses including, without limitation, the rights to: copy, distribute, transmit, publicly display, publicly perform, reproduce, edit, translate and reformat your Submission; and to publish your name in connection with your Submission.</p>

<p>No compensation will be paid with respect to the use of your Submission, as provided herein. NumberKarma is under no obligation to post or use any Submission you may provide and may remove any Submission at any time in NumberKarma&apos;s sole discretion.</p>

<p>By posting, uploading, inputting, providing or submitting your Submission you warrant and represent that you own or otherwise control all of the rights to your Submission as described in this section including, without limitation, all the rights necessary for you to provide, post, upload, input or submit the Submissions.</p>

<p><strong>Third Party Accounts</strong></p>
<p>You will be able to connect your NumberKarma account to third party accounts. By connecting your NumberKarma account to your third party account, you acknowledge and agree that you are consenting to the continuous release of information about you to others (in accordance with your privacy settings on those third party sites). If you do not want information about you to be shared in this manner, do not use this feature.</p>

<p><strong>International Users</strong></p>
<p>The Service is controlled, operated and administered by NumberKarma from our offices within the USA. If you access the Service from a location outside the USA, you are responsible for compliance with all local laws. You agree that you will not use the NumberKarma Content accessed through www.numberkarma.com in any country or in any manner prohibited by any applicable laws, restrictions or regulations.</p>

<p><strong>Indemnification</strong></p>
<p>You agree to indemnify, defend and hold harmless NumberKarma, its officers, directors, employees, agents and third parties, for any losses, costs, liabilities and expenses (including reasonable attorney&apos;s fees) relating to or arising out of your use of or inability to use the Site or services, any user postings made by you, your violation of any terms of this Agreement or your violation of any rights of a third party, or your violation of any applicable laws, rules or regulations. NumberKarma reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with NumberKarma in asserting any available defenses.</p>

<p><strong>Arbitration</strong></p>
<p>In the event the parties are not able to resolve any dispute between them arising out of or concerning these Terms and Conditions, or any provisions hereof, whether in contract, tort, or otherwise at law or in equity for damages or any other relief, then such dispute shall be resolved only by final and binding arbitration pursuant to the Federal Arbitration Act, conducted by a single neutral arbitrator and administered by the American Arbitration Association, or a similar arbitration service selected by the parties, in a location mutually agreed upon by the parties. The arbitrator&apos;s award shall be final, and judgment may be entered upon it in any court having jurisdiction. In the event that any legal or equitable action, proceeding or arbitration arises out of or concerns these Terms and Conditions, the prevailing party shall be entitled to recover its costs and reasonable attorney&apos;s fees. The parties agree to arbitrate all disputes and claims in regards to these Terms and Conditions or any disputes arising as a result of these Terms and Conditions, whether directly or indirectly, including Tort claims that are a result of these Terms and Conditions. The parties agree that the Federal Arbitration Act governs the interpretation and enforcement of this provision. The entire dispute, including the scope and enforceability of this arbitration provision shall be determined by the Arbitrator. This arbitration provision shall survive the termination of these Terms and Conditions.</p>

<p><strong>Class Action Waiver</strong></p>
<p>Any arbitration under these Terms and Conditions will take place on an individual basis; class arbitrations and class/representative/collective actions are not permitted. THE PARTIES AGREE THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY IN EACH&apos;S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE AND/ OR REPRESENTATIVE PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE ATTORNEY GENERAL ACTION AGAINST THE OTHER. Further, unless both you and NumberKarma agree otherwise, the arbitrator may not consolidate more than one person&apos;s claims, and may not otherwise preside over any form of a representative or class proceeding.</p>

<p><strong>Liability Disclaimer</strong></p>
<p>THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. NUMBER KARMA, LLC AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.</p>

<p>NUMBER KARMA, LLC AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED &quot;AS IS&quot; WITHOUT WARRANTY OR CONDITION OF ANY KIND. NUMBER KARMA, LLC AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.</p>

<p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL NUMBER KARMA, LLC AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF NUMBER KARMA, LLC OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.</p>

<p><strong>Termination/Access Restriction&nbsp;</strong></p>
<p>NumberKarma reserves the right, in its sole discretion, to terminate your access to the Site and the related services or any portion thereof at any time, without notice. To the maximum extent permitted by law, this agreement is governed by the laws of the State of Georgia and you hereby consent to the exclusive jurisdiction and venue of courts in Georgia in all disputes arising out of or relating to the use of the Site. Use of the Site is unauthorized in any jurisdiction that does not give effect to all provisions of these Terms, including, without limitation, this section.</p>

<p>You agree that no joint venture, partnership, employment, or agency relationship exists between you and NumberKarma as a result of this agreement or use of the Site. NumberKarma&apos;s performance of this agreement is subject to existing laws and legal process, and nothing contained in this agreement is in derogation of NumberKarma&apos;s right to comply with governmental, court and law enforcement requests or requirements relating to your use of the Site or information provided to or gathered by NumberKarma with respect to such use. If any part of this agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the agreement shall continue in effect.</p>

<p>Unless otherwise specified herein, this agreement constitutes the entire agreement between the user and NumberKarma with respect to the Site and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between the user and NumberKarma with respect to the Site. A printed version of this agreement and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. It is the express wish to the parties that this agreement and all related documents be written in English.</p>

<p><strong>Changes to Terms</strong></p>
<p>NumberKarma reserves the right, in its sole discretion, to change the Terms under which www.numberkarma.com is offered. The most current version of the Terms will supersede all previous versions. NumberKarma encourages you to periodically review the Terms to stay informed of our updates.</p>

<p><strong>Contact Us</strong></p>
<p>NumberKarma welcomes your questions or comments regarding the Terms:</p>

<p>Number Karma, LLC</p>
<p>525 Tribble Gap Rd #3351</p>
<p>CUMMING, Georgia 30040</p>


<p>Email Address:</p>
<p>admin@numberkarma.com</p>

<p>Telephone number:</p>
<p>+1 44 33 44 33 44</p>

<p>Effective as of January 23, 2024</p>

            </Container>
        </>
    );
}

export default TermsOfUse;


