import React, { useState } from 'react';
import { Col, Container, Row, Button, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import NumberEnquiryForm from './NumberEnquiryForm';
import SupportEnquiryForm from './SupportEnquiryForm';
import CallbackRequestForm from './CallbackRequestForm';
import './Connected.css'

function Connected() {
  const [showModal, setShowModal] = useState(false);
  const [enquiryType, setEnquiryType] = useState('');

  const handleShowModal = (type) => {
    setEnquiryType(type);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setEnquiryType('');
    setShowModal(false);
  };

  return (
    <>
      <section className="connectedSection" style={{ backgroundColor: '#f4f4f4', padding: '40px 0' }}>
        <Container>
          <Row>
            <Col md={12}>
              <p className="px-0 py-2 m-0 sideheading">Stay connected</p>
              <hr />
              <Row className="connected">
                <Col md={6} className="mt-4">
                  <h4>Always be the first to know.</h4>
                  <p className="mb-4">Sign up for our newsletter!</p>
                  <p className="mb-5">
                    Subscribe to receive news, promotions, and recommendations
                    about Vanity Numbers and services from Number Karma.
                  </p>
                </Col>

                <Col md={6} className="d-flex align-items-center justify-content-between">

                  <div className="input-group">
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Email us"
                      aria-describedby="button-addon2"
                      style={{ fontSize: '14px' }}
                    />
                    <div className="p-2">
                      <Button className="btn btn-dark" type="submit">
                        Subscribe Now
                      </Button>
                    </div>
                  </div>

                </Col>
              </Row>
              <hr />
              {/* <Row className="d-flex justify-content-center connectedColumns mt-4">
                <Col md={4} className="p-0 text-center" onClick={() => handleShowModal('number')}>
                  <p className="m-0 px-0 cursor-pointer">Send Enquiry For Number</p>
                </Col>
                <Col md={4} className="p-0 bordersonside text-center" onClick={() => handleShowModal('support')}>
                  <p className="m-0 cursor-pointer">Send Enquiry For Support</p>
                </Col>
                <Col md={4} className="p-0 text-center" onClick={() => handleShowModal('callback')}>
                  <p className="m-0 cursor-pointer">Request Callback</p>
                </Col>
              </Row> */}
            </Col>
          </Row>
        </Container>

        {/* Modal */}
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              {enquiryType === 'subscribe'
                ? 'Subscribe Now'
                : enquiryType === 'number'
                  ? 'Send Enquiry For Number'
                  : enquiryType === 'support'
                    ? 'Send Enquiry For Support'
                    : 'Request Callback'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {enquiryType === 'number' && (
              <NumberEnquiryForm onClose={handleCloseModal} />
            )}
            {enquiryType === 'support' && (
              <SupportEnquiryForm onClose={handleCloseModal} />
            )}
            {enquiryType === 'callback' && (
              <CallbackRequestForm onClose={handleCloseModal} />
            )}
          </Modal.Body>

        </Modal>
      </section>
    </>
  );
}

export default Connected;
