import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import "./Pattern.css"; 

function Pattern() {
    
    const [patternData, setPatternData] = useState([]);

    useEffect(() => {
        axios.get('https://vanityapi.adef.tech/Home/patternDropdown')
            .then(response => {
                setPatternData(response.data);
                
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    }, []);

    const patternList = patternData.map(item => (
        <Col md={4} key={item.id}>
            <Link to={`/patternnumbers/${item.pattern1}`}>
                <div className='pattern-widget'>
                    <p className="pattern-name">{item.pattern1}</p>
                   
                </div>
            </Link>
        </Col>
    ));

    return (
        <Container fluid>
            <Row className='mt-5'>
                <Col md={4}></Col>
                <Col md={4} className='text-center'>
                    <h2>Pattern List</h2>
                </Col>
                <Col md={4}></Col>
            </Row>
            <Container>
                <Row className='mt-3'>
                    {patternList}
                </Row>
            </Container>
        </Container>
    );
}

export default Pattern;
