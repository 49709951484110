import React, { createContext, useContext, useState } from 'react';
import "./Cart"

var CartContext = createContext();

export let CartProvider = ({ children }) => {
  var [cartCount, setCartCount] = useState(0);

  var updateCartCount = (count) => {
    setCartCount(count);
  };

  return (
    <CartContext.Provider value={{ cartCount, updateCartCount }}>
      {children}
    </CartContext.Provider>
  );
};

export let useCart = () => {
  
  return useContext(CartContext).cartCount;
};
