import React, { useEffect, useState } from "react";
import axios from "axios";
import Constants from "./Constants";

function InitiatePayment() {
  const storedAuthorId = sessionStorage.getItem("authorid");
  const [cartAmount, setCartAmount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [addressBookId, setAddressBookId] = useState(null); // State to store addressbook_id
  const Project_Id = Constants.PROJECTID;

  useEffect(() => {
    const fetchCartAmount = async () => {
      const cartid = sessionStorage.getItem("cart_id");
      if (cartid) {
        try {
          const response = await axios.get(
            `https://vanityapi.adef.tech/Cart/Cartamount?project_id=${Project_Id}&cart_id=${cartid}`
          );

          if (response.data) {
            const amount = parseFloat(response.data.amount).toFixed(2);
            setCartAmount(amount);
          }
        } catch (error) {
          console.error("API Error:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchCartAmount();
  }, [Project_Id]);

  useEffect(() => {
    // Fetch address book and get the addressbook_id
    const fetchAddressBook = async () => {
      try {
        const response = await axios.get(
            `https://vanityapi.adef.tech/Addressbook/Index?project_id=${Project_Id}&authorid=${storedAuthorId}`
          );
          
          const primaryAddress = response.data.find(entry => entry.isprimary === true);

          if (primaryAddress) {
            setAddressBookId(primaryAddress.id);
          }
      } catch (error) {
        console.error("API Error:", error);
      }
    };

    fetchAddressBook();
  }, [storedAuthorId, Project_Id]); // Fetch address book once on component mount

  useEffect(() => {
    if (!loading && cartAmount > 0 && storedAuthorId && addressBookId) {
      initiatePayment(cartAmount, storedAuthorId, addressBookId);
    }
  }, [loading, cartAmount, storedAuthorId, addressBookId]);

  function initiatePayment(amount, authorid, addressbook_id) {
    const cart_id = sessionStorage.getItem("cart_id");
    const transaction_id = null;

    fetch("https://vanityapi.adef.tech/Bill/Create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        amount,
        authorid,
        cart_id,
        project_id: Project_Id,
        transaction_id,
        addressbook_id,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === 1) {
            
          window.location = "/profile";

        } else {
          // Handle payment initiation failure
          // console.log('Payment initiation failed:', data.errorMessage);
        }
      })
      .catch((error) => {
        // Handle fetch error
        // console.log('Fetch error:', error);
      });
  }

  return (
    <>
      {/* You can add loading indicators or other UI elements here if needed */}
    </>
  );
}

export default InitiatePayment;
