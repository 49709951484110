import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    slidesToSlide: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
    slidesToSlide: 4,
  },
  mobile: {
    breakpoint: { max: 767, min: 464 },
    items: 2,
    slidesToSlide: 1,
  },
  smallerMobile: {
    breakpoint: { max: 463, min: 0 }, 
    items: 1,
    slidesToSlide: 1,
  },
};

const sliderImageData = [
  { url: "/images/1.jpeg" },
  { url: "/images/2.jpg" },
  { url: "/images/3.webp" },
  { url: "/images/4.jpg" },
  { url: "/images/5.png" },
  { url: "/images/6.jpg" },
  { url: "/images/7.png" },
  { url: "/images/8.webp" },
  { url: "/images/9.jpg" },
  { url: "/images/10.png" },
  { url: "/images/11.jpg" },
  { url: "/images/12.gif" },
  { url: "/images/13.jpg" },
  { url: "/images/14.jpg" },
  { url: "/images/15.png" },
  { url: "/images/16.jpg" },
];

const Clients = () => {
  return (
    <>
      <Container className="py-5">
        <div className="text-center mb-4">
          <h2>Our Trusted Network Operators</h2>
        </div>

        {/* Carousel with images */}
        <Carousel
          responsive={responsive}
          autoPlay={true}
          swipeable={true}
          draggable={true}
          showDots={false}
          infinite={true}
          partialVisible={false}
          dotListClass="custom-dot-list-style"
          customTransition="transform 1000ms ease-in-out"
          customTransitionEnd={(state) => {
            // Check if it's the last item and reset the transition
            if (state.currentSlide === sliderImageData.length - 1) {
              setTimeout(() => {
                state.transition = "none";
                state.currentSlide = 0;
              }, 0);
            }
          }}
        >
          {sliderImageData.map((image, index) => (
            <div key={index}>
              <img
                src={image.url}
                alt={`client-${index}`}
                style={{ height: "100px" }}
              />
            </div>
          ))}
        </Carousel>
      </Container>
      
      
    </>
  );
};

export default Clients;
