import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import axios from "axios";
import "./Cart.css";
import "./Checkout.css";
import { Link } from "react-router-dom";
import Constants from "./Constants";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Checkout() {
  const storedAuthorId = sessionStorage.getItem("authorid");
  const [cartData, setCartData] = useState([]);
  const [cartCount, setCartCount] = useState(0); // State to store the cart count
  const [cartAmount, setCartAmount] = useState(0);
  const [loading, setLoading] = useState(true);
  const Project_Id = Constants.PROJECTID;

  const [formData, setFormData] = useState({
    firstname: "",
    phone: "",
    address: "",
    pincode: "",
    city: "",
    state: "",
    project_id: Project_Id,
    authorId: storedAuthorId,
    isprimary: true,
  });

  const [showForm, setShowForm] = useState(false); // State to manage the visibility of the form

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleToggleForm = () => {
    setShowForm(!showForm);
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  const handleBillAddress = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://vanityapi.adef.tech/Addressbook/Create",
        formData
      );
      const showSuccessToast = () => {
        toast.success(response.data.responsemessage, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      };
       // Call the showSuccessToast function
      showSuccessToast();
      // You can add further actions or notifications here
    } catch (error) {
      console.error();
      const showErrorToast = () => {
        toast.error("Error Adding:", error, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      };
      showErrorToast();
      // You can display an error message to the user
    }
  };

  useEffect(() => {
    const cartid = sessionStorage.getItem("cart_id");
    if (cartid) {
      axios
        .get(
          `https://vanityapi.adef.tech/Cart/Index?project_id=${Project_Id}&cart_id=${cartid}`
        )
        .then((response) => {
          if (response.data.length > 0 && response.data !== "Cart is empty") {
            setCartData(response.data);
            setCartCount(response.data.length); // Set the cart count
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error("API Error:", error);
          setLoading(false);
        });
      // cart amount
      axios
        .get(
          `https://vanityapi.adef.tech/Cart/Cartamount?project_id=${Project_Id}&cart_id=${cartid}`
        )
        .then((response) => {
          if (response.data) {
            const amount = parseFloat(response.data.amount).toFixed(2);
            setCartAmount(amount); // Set the cart amount
          }
        })
        .catch((error) => {
          console.error("API Error:", error);
        });
    } else {
      setLoading(false);
    }
  }, [Project_Id]);

  if (loading) {
    // You can display a loading spinner here while data is being fetched.
    return <p>Loading...</p>;
  }

  if (cartData.length === 0) {
    return (
      <Container>
        <h2 className="text-center pageTitle my-5">CART</h2>
        <p>Your Shopping cart is currently empty.</p>
      </Container>
    );
  }

  return (
    <>
      <Container>
        <h2 className="text-center pageTitle my-5">Checkout ({cartCount})</h2>
      </Container>
      <Container className="container-color">
        <p>Billing Address:</p>

        {showForm ? (
          <Form onSubmit={handleBillAddress}>
            <Row>
              <h3>Add new address</h3>
              <Col md={6}>
                <Form.Group controlId="formBasicName">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Full Name"
                    name="firstname"
                    value={formData.firstname}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formMobile">
                  <Form.Label>Mobile Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Mobile Number"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                {/* <Form.Group controlId="formCountry">
                  <Form.Label>Country / Region</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Country"
                    name="country"
                    value={formData.country}
                    onChange={handleInputChange}
                  />
                </Form.Group> */}
              </Col>
              <Col md={6}></Col>
              <Col md={6}>
                <Form.Group controlId="formZip">
                  <Form.Label>Zip Code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Zip Code"
                    name="pincode"
                    value={formData.pincode}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}></Col>
              <Col md={12}>
                <Form.Group controlId="formAddress">
                  <Form.Label>Address:</Form.Label>
                  <Form.Control
                    type="textarea"
                    placeholder="Enter your Adddress"
                    name="address"
                    value={formData.address}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group controlId="formCity">
                  <Form.Label>City / Town</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your City"
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formCountry">
                  <Form.Label>State / Region</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your State / Region"
                    name="state"
                    value={formData.state}
                    onChange={handleInputChange}
                  />
                  <p>Note: This will be your Billing address.</p>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Row className="mt-5">
                  <Col>
                    <Link to="/checkout" className="small text-muted">
                      <Button
                        className="form-control btn"
                        onClick={handleCloseForm}
                      >
                        Cancel
                      </Button>
                    </Link>
                  </Col>
                  <Col>
                    <Button className="form-control btn" type="submit">
                      Save
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        ) : (
          <Row>
            <Col
              md={{ span: 6, offset: 3 }}
              className="py-5 my-5 addaddbtn text-center"
            >
              <div className="plusicon mb-5" onClick={handleToggleForm}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M18 13H13V18C13 18.55 12.55 19 12 19C11.45 19 11 18.55 11 18V13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H11V6C11 5.45 11.45 5 12 5C12.55 5 13 5.45 13 6V11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z"
                    fill="black"
                  />
                </svg>
              </div>
              <p className="m-0 addresstext">Add New Address</p>
            </Col>
          </Row>
        )}
      </Container>
      <Container className="mt-4 p-0">
        <p className="timer">Time Out : 30:00</p>
      </Container>
      <Container className="container-color">
        <p>Order Summary:</p>
        <Table>
          <tbody>
            {cartData.map((item) => (
              <tr key={item.product_id} className="cartTableRow">
                <td>
                  <p className="numbername">{item.name}</p>
                  <p className="location">{item.state}</p>
                </td>
                <td className="text-center">{item.categoryname}</td>

                <td className="text-center price">
                  ${parseFloat(item.price).toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Row>
          <Col md={7}></Col>
          <Col md={5} className="">
            <Row>
              <Col md={6}>
                <p>Items Subtotal</p>
              </Col>
              <Col md={6}>
                <p className="text-end"></p>
              </Col>
              <Col md={6}>
                <p>Shipping fee</p>
              </Col>
              <Col md={6}>
                <p className="text-end"></p>
              </Col>
            </Row>
            <hr></hr>
            <Row className="pt-4">
              <Col md={6}>
                <p>Total</p>
              </Col>
              <Col md={6}>
                <p className="m-0 cartamount text-end"> ${cartAmount}</p>
              </Col>
            </Row>

            <p className="my-5">Payment at next step</p>
            <p>
              I have order and agree to the Terms of Sale, Privacy policy &
              Number Karma, Terms & Conditions.{" "}
            </p>
            <Link to="/paypal">
              <Button className="btn" style={{ width: '100%' }}>Place the order</Button>
            </Link>
          </Col>
        </Row>
      </Container>
      <Container className="py-0">
        <Row className="connectedColumns">
          <Col md={4} className="p-0">
            <p className="m-0 px-0">
              Product Help
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
              >
                <path
                  d="M15.1307 22.4941C15.0494 22.4128 14.9848 22.3163 14.9408 22.2101C14.8968 22.1038 14.8741 21.99 14.8741 21.875C14.8741 21.76 14.8968 21.6461 14.9408 21.5399C14.9848 21.4337 15.0494 21.3372 15.1307 21.2559L21.5128 14.875L4.3748 14.875C4.14274 14.875 3.92018 14.7828 3.75608 14.6187C3.59199 14.4546 3.4998 14.2321 3.4998 14C3.4998 13.7679 3.59199 13.5454 3.75608 13.3813C3.92018 13.2172 4.14274 13.125 4.3748 13.125L21.5128 13.125L15.1307 6.74406C14.9666 6.57987 14.8743 6.35719 14.8743 6.125C14.8743 5.8928 14.9666 5.67012 15.1307 5.50594C15.2949 5.34175 15.5176 5.24951 15.7498 5.24951C15.982 5.24951 16.2047 5.34175 16.3689 5.50594L24.2439 13.3809C24.3252 13.4622 24.3898 13.5587 24.4338 13.6649C24.4778 13.7711 24.5005 13.885 24.5005 14C24.5005 14.115 24.4778 14.2288 24.4338 14.3351C24.3898 14.4413 24.3252 14.5378 24.2439 14.6191L16.3689 22.4941C16.2876 22.5754 16.1911 22.64 16.0849 22.684C15.9786 22.728 15.8648 22.7507 15.7498 22.7507C15.6348 22.7507 15.521 22.728 15.4147 22.684C15.3085 22.64 15.212 22.5754 15.1307 22.4941Z"
                  fill="black"
                />
              </svg>
            </p>
          </Col>
          <Col md={4} className="p-0 bordersonside">
            <p className="m-0">
              Shopping FAQ’s
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
              >
                <path
                  d="M15.1307 22.4941C15.0494 22.4128 14.9848 22.3163 14.9408 22.2101C14.8968 22.1038 14.8741 21.99 14.8741 21.875C14.8741 21.76 14.8968 21.6461 14.9408 21.5399C14.9848 21.4337 15.0494 21.3372 15.1307 21.2559L21.5128 14.875L4.3748 14.875C4.14274 14.875 3.92018 14.7828 3.75608 14.6187C3.59199 14.4546 3.4998 14.2321 3.4998 14C3.4998 13.7679 3.59199 13.5454 3.75608 13.3813C3.92018 13.2172 4.14274 13.125 4.3748 13.125L21.5128 13.125L15.1307 6.74406C14.9666 6.57987 14.8743 6.35719 14.8743 6.125C14.8743 5.8928 14.9666 5.67012 15.1307 5.50594C15.2949 5.34175 15.5176 5.24951 15.7498 5.24951C15.982 5.24951 16.2047 5.34175 16.3689 5.50594L24.2439 13.3809C24.3252 13.4622 24.3898 13.5587 24.4338 13.6649C24.4778 13.7711 24.5005 13.885 24.5005 14C24.5005 14.115 24.4778 14.2288 24.4338 14.3351C24.3898 14.4413 24.3252 14.5378 24.2439 14.6191L16.3689 22.4941C16.2876 22.5754 16.1911 22.64 16.0849 22.684C15.9786 22.728 15.8648 22.7507 15.7498 22.7507C15.6348 22.7507 15.521 22.728 15.4147 22.684C15.3085 22.64 15.212 22.5754 15.1307 22.4941Z"
                  fill="black"
                />
              </svg>
            </p>
          </Col>
          <Col md={4} className="p-0">
            <p className="m-0">
              Troubleshooting
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
              >
                <path
                  d="M15.1307 22.4941C15.0494 22.4128 14.9848 22.3163 14.9408 22.2101C14.8968 22.1038 14.8741 21.99 14.8741 21.875C14.8741 21.76 14.8968 21.6461 14.9408 21.5399C14.9848 21.4337 15.0494 21.3372 15.1307 21.2559L21.5128 14.875L4.3748 14.875C4.14274 14.875 3.92018 14.7828 3.75608 14.6187C3.59199 14.4546 3.4998 14.2321 3.4998 14C3.4998 13.7679 3.59199 13.5454 3.75608 13.3813C3.92018 13.2172 4.14274 13.125 4.3748 13.125L21.5128 13.125L15.1307 6.74406C14.9666 6.57987 14.8743 6.35719 14.8743 6.125C14.8743 5.8928 14.9666 5.67012 15.1307 5.50594C15.2949 5.34175 15.5176 5.24951 15.7498 5.24951C15.982 5.24951 16.2047 5.34175 16.3689 5.50594L24.2439 13.3809C24.3252 13.4622 24.3898 13.5587 24.4338 13.6649C24.4778 13.7711 24.5005 13.885 24.5005 14C24.5005 14.115 24.4778 14.2288 24.4338 14.3351C24.3898 14.4413 24.3252 14.5378 24.2439 14.6191L16.3689 22.4941C16.2876 22.5754 16.1911 22.64 16.0849 22.684C15.9786 22.728 15.8648 22.7507 15.7498 22.7507C15.6348 22.7507 15.521 22.728 15.4147 22.684C15.3085 22.64 15.212 22.5754 15.1307 22.4941Z"
                  fill="black"
                />
              </svg>
            </p>
          </Col>
        </Row>
      </Container>
      <ToastContainer/>
    </>
  );
}

export default Checkout;
