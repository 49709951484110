import React, { useEffect, useState } from 'react';
import { PayPalButton } from 'react-paypal-button-v2';
import "bootstrap/dist/css/bootstrap.min.css";
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-bootstrap';

const PaypalSIM = ({ amount, orderId }) => {
  const authKey = sessionStorage.getItem('authorid');
    
  const onCancel = (data) => {
    // Handle payment cancellation
    console.log('Payment cancelled', data);
  };

  const onError = (err) => {
    // Handle errors
    console.error('Error during payment', err);
  };

  const PortingtoAPI = async (customerid, phonenumberid) => {
    try {
      const url = 'https://vanityapi.adef.tech/Review/SimDelivery';

      const requestData = {
        customerid,
        phonenumberid,
      };

      const data = new URLSearchParams(requestData);

      const response = await fetch(url, {
        method: 'POST',
        body: data,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        if (responseData.responseCode === 0) {
          const showSuccessToast = () => {
            
            toast.success(responseData.responsemessage, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              onClose: () => {
                window.location.reload();
              },
            });
          };
          showSuccessToast();
          console.log('SIM delivery successful:', responseData.responsemessage);
        } else {
          const showErrorToast = () => {
            toast.error(responseData.responsemessage, {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          };
          showErrorToast();
          console.error('SIM delivery failed:', responseData.responsemessage);
        }
      } else {
        console.error('Server error:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
      <div className='d-flex justify-content-center py-5'>
        <PayPalButton
          amount={amount}
          onSuccess={(details, data) => {
            // Include requestData in the options
            PortingtoAPI(authKey, orderId);
          }}
          onCancel={onCancel}
          onError={onError}
          options={{
            // live
            clientId: 'ARkAwgblSGgWAqLmFzq26yZmFXd8YgJK4ep0BFaxnQxWzG-hwJGpE3zIbBeuX6HXePQFzl-sgscUh5nK',
            // test
            // clientId: "AX-ROSkCxK1W1uwSJFxl2nwe5Q2TJMmmYYZNnhHXSEDO7cgh8CvogLUirE1um46FrTMYcdxjMQEqe3VM",
            currency: 'USD',
            custom: JSON.stringify({
              customerid: authKey,
              phonenumberid: orderId,
            }),
          }}
        />
      </div>
      <ToastContainer></ToastContainer>
    </>
  );
};

export default PaypalSIM;
